<template>
    <div v-if="quote">
        <div class="form-group row mt-5">
            <label class="col-2 col-form-label text-right">Commission Type</label>
            <div class="col-2">
                <ValidationProvider rules="required" name="commissionType" v-slot="{ errors }">
                    <b-form-radio-group class="mt-3" v-model="quote.K_CommissionType" :options="commissionTypes"
                        @change="commissionTypeChanged" :disabled="isReadOnly"></b-form-radio-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="form-group row" v-if="quote.K_CommissionType === 'Gross'">
            <label class="col-2 col-form-label text-right">Insurer Commission (ex. GST)</label>
            <div class="col-2">
                <ValidationProvider rules="required" name="insurerCommission" v-slot="{ errors }">
                    <currency-input class="form-control" v-model="quote.K_InsurerCommission"
                        @input="insurerCommissionChanged" :disabled="isReadOnly" />
                    <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <label class="col-2 col-form-label text-right">Insurer Commission GST</label>
            <div class="col-2">
                <ValidationProvider rules="required" name="insurerCommissionGst" v-slot="{ errors }">
                    <currency-input class="form-control" v-model="quote.K_InsurerCommissionGst" disabled />
                    <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <label class="col-2 col-form-label text-right">Insurer Commission %</label>
            <div class="col-2">
                <ValidationProvider rules="required" name="insurerCommissionPercent" v-slot="{ errors }">
                    <b-input-group append="%">
                        <input type="number" class="form-control" v-model="quote.K_InsurerCommissionPercent" disabled
                            step="0.01" :number="true" style="appearance: textfield;" />
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div v-if="showCommissions">
            <div class="form-group row mt-5">
                <label class="col-2 col-form-label text-right">Broker Fee (ex. GST)</label>
                <div class="col-2">
                    <ValidationProvider rules="required" name="brokerFee" v-slot="{ errors }">
                        <currency-input class="form-control" v-model="brokerFee" @input="brokerFeeChanged"
                            :disabled="isReadOnly || quote.K_CommissionType === 'Gross'" />
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">Broker Fee GST</label>
                <div class="col-2">
                    <ValidationProvider rules="required" name="brokerFeeGst" v-slot="{ errors }">
                        <currency-input class="form-control" v-model="brokerFeeGst" disabled />
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-2 col-form-label text-right">AR Income %</label>
                <div class="col-2">
                    <ValidationProvider rules="required" name="arIncomePercent" v-slot="{ errors }">
                        <b-input-group append="%">
                            <input type="number" class="form-control" v-model="quote.K_ArIncomePercent"
                                @input="arIncomePercentChanged" min="0" max="100" :disabled="isReadOnly" />
                        </b-input-group>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">AR Income (ex. GST)</label>
                <div class="col-2">
                    <ValidationProvider rules="required" name="arIncome" v-slot="{ errors }">
                        <currency-input class="form-control" v-model="arIncomeExGst" @input="arIncomeChanged"
                            :disabled="isReadOnly" />
                        <span class="text-danger">{{ errors[0] }}</span>
                        <span v-if="arIncomeExceedsLimit" class="text-danger">Total income cannot exceed Insurer
                            Commission</span>
                    </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">AR Income GST</label>
                <div class="col-2">
                    <ValidationProvider rules="required" name="arIncomeGst" v-slot="{ errors }">
                        <currency-input class="form-control" v-model="arIncomeGst" disabled />
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-2 col-form-label text-right">CIB Income %</label>
                <div class="col-2">
                    <ValidationProvider rules="required" name="cibIncomePercent" v-slot="{ errors }">
                        <b-input-group append="%">
                            <input type="number" class="form-control" v-model="quote.K_CibIncomePercent"
                                @input="cibIncomePercentChanged" min="0" max="100" :disabled="isReadOnly" />
                        </b-input-group>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">CIB Income (ex. GST)</label>
                <div class="col-2">
                    <ValidationProvider rules="required" name="totalCibIncome" v-slot="{ errors }">
                        <currency-input class="form-control" v-model="cibIncomeExGst" @input="cibIncomeChanged"
                            :disabled="isReadOnly" />
                        <span class="text-danger">{{ errors[0] }}</span>
                        <span v-if="cibIncomeExceedsLimit" class="text-danger">Total income cannot exceed Insurer
                            Commission</span>
                    </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">CIB Income GST</label>
                <div class="col-2">
                    <ValidationProvider rules="required" name="totalCibIncomeGst" v-slot="{ errors }">
                        <currency-input class="form-control" v-model="cibIncomeGst" disabled />
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
</style>

<script>
//@ts-ignore
import _ from "lodash";
import { extend } from 'vee-validate';

// Define custom validation rule
extend('max-total-income', {
    validate: (value, [maxValue]) => {
        return Number(value) <= Number(maxValue);
    },
    params: ['maxValue'],
    message: 'Total income cannot exceed Insurer Commission'
});

export default {
    name: "Instructions",
    props: {
        quote: Object,
        isReadOnly: Boolean,
        showCommissions: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            defaultNetSplit: {
                ar: 50,
                cib: 50
            },
            defaultGrossSplit: {
                ar: 95,
                cib: 5
            },
            tempArIncome: 0,
            tempCibIncome: 0,
            isExceedingLimit: false,
            showValidationError: false,
            isInitialMount: true,
        };
    },

    created() {
        this.debouncedArIncomeChanged = _.debounce(this.handleArIncomeChange, 500);
        this.debouncedCibIncomeChanged = _.debounce(this.handleCibIncomeChange, 500);

        // Longer delay for validation to prevent flashing
        this.debouncedValidation = _.debounce(this.validateTotalIncome, 700);

        // Add a separate debounce for showing validation errors
        this.debouncedShowValidation = _.debounce(() => {
            if (!this.isInitialMount) {  // Only show validation after initial mount
                this.showValidationError = this.isExceedingLimit;
            }
        }, 1000);
    },

    mounted() {
        // Wait for next tick to ensure all values are properly set
        this.$nextTick(() => {
            // Do initial validation without showing error message
            if (this.quote.K_CommissionType === 'Gross') {
                const totalIncome = this.round(Number(this.quote.K_ArIncome || 0) + Number(this.quote.K_CibIncome || 0));
                const insurerCommission = this.round(Number(this.quote.K_InsurerCommission || 0));
                this.isExceedingLimit = totalIncome > insurerCommission;
                this.$emit('validation-changed', this.isExceedingLimit);
            }
            // Reset the initial mount flag after a brief delay
            setTimeout(() => {
                this.isInitialMount = false;
            }, 2000);
        });
    },

    beforeDestroy() {
        // Cancel any pending debounced calls when component is destroyed
        this.debouncedArIncomeChanged.cancel();
        this.debouncedCibIncomeChanged.cancel();
        this.debouncedValidation.cancel();
        this.debouncedShowValidation.cancel();
    },

    computed: {
        isInvalid() {
            if (this.quote.K_CommissionType === 'Gross') {
                const totalIncome = this.round(Number(this.arIncomeExGst || 0) + Number(this.cibIncomeExGst || 0));
                const insurerCommission = this.round(Number(this.quote.K_InsurerCommission || 0));
                return totalIncome > insurerCommission;
            }
            return false;
        },

        arIncomeExceedsLimit() {
            return this.showValidationError;
        },

        cibIncomeExceedsLimit() {
            return this.showValidationError;
        },

        commissionTypes: function () {
            return [
                { text: "Net", value: "Net" },
                { text: "Gross", value: "Gross" },
            ];
        },

        arIncomeExceedsLimit() {
            return !this.isInitialMount && this.showValidationError;
        },

        cibIncomeExceedsLimit() {
            return !this.isInitialMount && this.showValidationError;
        },

        brokerFeeGst: {
            get() {
                // If null or empty, return 0
                return this.quote && (this.quote.K_BrokerFeeGst || 0);
            },
            set(value) {
                if (this.quote) {
                    this.quote.K_BrokerFeeGst = value;
                }
            }
        },
        brokerFee: {
            get() {
                // If null or empty, return 0
                return this.quote && (this.quote.K_BrokerFee || 0);
            },
            set(value) {
                if (this.quote) {
                    this.quote.K_BrokerFee = value;
                }
            }
        },
        arIncomePercent: {
            get() {
                // If null or empty, return 0
                return this.quote && (this.quote.K_ArIncomePercent || 0);
            },
            set(value) {
                if (this.quote) {
                    this.quote.K_ArIncomePercent = value;
                }
            }
        },
        cibIncomePercent: {
            get() {
                // If null or empty, return 0
                return this.quote && (this.quote.K_CibIncomePercent || 0);
            },
            set(value) {
                if (this.quote) {
                    this.quote.K_CibIncomePercent = value;
                }
            }
        },
        arIncomeExGst: {
            get() {
                // If null or empty, return 0
                return this.quote && (this.quote.K_ArIncome || 0);
            },
            set(value) {
                if (this.quote) {
                    this.quote.K_ArIncome = value;
                }
            }
        },
        arIncomeGst: {
            get() {
                // If null or empty, return 0
                return this.quote && (this.quote.K_ArIncomeGst || 0);
            },
            set(value) {
                if (this.quote) {
                    this.quote.K_ArIncomeGst = value;
                }
            }
        },
        cibIncomeExGst: {
            get() {
                // If null or empty, return 0
                return this.quote && (this.quote.K_CibIncome || 0);
            },
            set(value) {
                if (this.quote) {
                    this.quote.K_CibIncome = value;
                }
            }
        },
        cibIncomeGst: {
            get() {
                // If null or empty, return 0
                return this.quote && (this.quote.K_CibIncomeGst || 0);
            },
            set(value) {
                if (this.quote) {
                    this.quote.K_CibIncomeGst = value;
                }
            }
        },
        totalCibIncome() {
            if (this.quote.K_CommissionType === "Gross") {
                return this.round(
                    this.calculatePercentAmount(this.quote.K_InsurerCommission, 5) +
                    (this.quote.K_BrokerFee || 0)
                );
            } else {
                // For 'Net' commission type, use the CIB Income value
                return this.quote.K_CibIncome || 0;
            }
        },
        totalCibIncomeGst() {
            return this.calculateGst(this.totalCibIncome);
        }
    },

    methods: {
        validateTotalIncome() {
            if (this.quote.K_CommissionType === 'Gross') {
                const totalIncome = this.round(Number(this.quote.K_ArIncome || 0) + Number(this.quote.K_CibIncome || 0));
                const insurerCommission = this.round(Number(this.quote.K_InsurerCommission || 0));

                this.isExceedingLimit = totalIncome > insurerCommission;
                this.$emit('validation-changed', this.isExceedingLimit);

                if (!this.isInitialMount) {
                    this.debouncedShowValidation();
                }
            } else {
                this.isExceedingLimit = false;
                this.showValidationError = false;
                this.$emit('validation-changed', false);
            }
        },

        getTotalIncome: function () {
            if (this.quote.K_CommissionType === "Gross") {
                return this.round(this.calculateSum(this.quote.K_InsurerCommission, this.quote.K_BrokerFee));
            } else {
                return this.quote.K_BrokerFee;
            }
        },

        updateCibIncomeGst(newGstValue) {
            this.$set(this.quote, 'K_CibIncomeGst', newGstValue);
        },

        commissionTypeChanged() {
            if (this.quote.K_CommissionType === "Net") {
                this.$set(this.quote, "K_ArIncomePercent", this.defaultNetSplit.ar);
                this.$set(this.quote, "K_CibIncomePercent", this.defaultNetSplit.cib);

                this.$set(this.quote, "K_InsurerCommission", null);
                this.$set(this.quote, "K_InsurerCommissionGst", null);
                this.$set(this.quote, "K_InsurerCommissionPercent", null);

                // Clear validation state
                this.isExceedingLimit = false;
                this.$emit('validation-changed', false);
            } else {
                this.$set(this.quote, "K_ArIncomePercent", this.defaultGrossSplit.ar);
                this.$set(this.quote, "K_CibIncomePercent", this.defaultGrossSplit.cib);
            }

            this.recalculateIncomes();
            this.debouncedValidation();
        },

        insurerCommissionChanged: function () {
            this.$set(
                this.quote,
                "K_InsurerCommissionGst",
                this.calculateGst(this.quote.K_InsurerCommission)
            );
            this.$set(
                this.quote,
                "K_InsurerCommissionPercent",
                this.calculatePercent(
                    this.quote.K_InsurerCommission,
                    this.quote.K_BasePremium
                )
            );

            if (this.quote.K_CommissionType === "Gross") {
                this.recalculateIncomes();
            }
        },

        brokerFeeChanged: function () {
            this.$set(
                this.quote,
                "K_BrokerFeeGst",
                this.calculateGst(this.quote.K_BrokerFee)
            );

            this.recalculateIncomes();
            this.$emit("brokerFeeChanged");
        },

        arIncomePercentChanged: function () {
            this.$set(this.quote, "K_CibIncomePercent", 100 - Number(this.quote.K_ArIncomePercent));
            this.recalculateIncomes();
        },


        // Update the input handlers to reset the initial mount flag
        arIncomeChanged(newValue) {
            this.isInitialMount = false;  // Reset flag on user input
            this.showValidationError = false;
            this.tempArIncome = Number(newValue || 0);
            this.debouncedArIncomeChanged();
            this.debouncedValidation();
        },

        handleArIncomeChange() {
            const value = this.tempArIncome;

            // For Gross commission type
            if (this.quote.K_CommissionType === 'Gross') {
                const totalAllowed = Number(this.quote.K_InsurerCommission || 0);

                // Check if value exceeds total allowed
                if (value > totalAllowed) {
                    // Only update the entered value and its GST
                    this.$set(this.quote, "K_ArIncome", value);
                    this.$set(this.quote, "K_ArIncomeGst", this.calculateGst(value));

                    // Calculate just the percentages of the current values
                    const arPercent = this.calculatePercent(value, totalAllowed);
                    const cibPercent = this.calculatePercent(this.quote.K_CibIncome || 0, totalAllowed);
                    this.$set(this.quote, "K_ArIncomePercent", arPercent);
                    this.$set(this.quote, "K_CibIncomePercent", cibPercent);
                    return;
                }

                // If valid, proceed with complementary calculations
                const cibIncome = totalAllowed - value;

                // Update all values
                this.$set(this.quote, "K_ArIncome", value);
                this.$set(this.quote, "K_ArIncomeGst", this.calculateGst(value));
                this.$set(this.quote, "K_CibIncome", cibIncome);
                this.$set(this.quote, "K_CibIncomeGst", this.calculateGst(cibIncome));

                // Calculate and update percentages
                const arPercent = this.calculatePercent(value, totalAllowed);
                const cibPercent = this.calculatePercent(cibIncome, totalAllowed);
                this.$set(this.quote, "K_ArIncomePercent", arPercent);
                this.$set(this.quote, "K_CibIncomePercent", cibPercent);

            } else {
                // For Net commission type - same logic as before
                const totalAllowed = Number(this.quote.K_BrokerFee || 0);
                const cibIncome = totalAllowed - value;

                this.$set(this.quote, "K_ArIncome", value);
                this.$set(this.quote, "K_ArIncomeGst", this.calculateGst(value));
                this.$set(this.quote, "K_CibIncome", cibIncome);
                this.$set(this.quote, "K_CibIncomeGst", this.calculateGst(cibIncome));

                const arPercent = this.calculatePercent(value, totalAllowed);
                const cibPercent = this.calculatePercent(cibIncome, totalAllowed);
                this.$set(this.quote, "K_ArIncomePercent", arPercent);
                this.$set(this.quote, "K_CibIncomePercent", cibPercent);
            }
        },

        cibIncomePercentChanged: function () {
            this.$set(this.quote, "K_ArIncomePercent", 100 - Number(this.quote.K_CibIncomePercent));
            this.recalculateIncomes();
        },

        calculateCibIncome: function (arIncome) {
            const totalIncome = this.getTotalIncome();
            const cibIncome = totalIncome - arIncome;
            return this.round(cibIncome);
        },

        cibIncomeChanged(newValue) {
            this.isInitialMount = false;  // Reset flag on user input
            this.showValidationError = false;
            this.tempCibIncome = Number(newValue || 0);
            this.debouncedCibIncomeChanged();
            this.debouncedValidation();
        },

        handleCibIncomeChange() {
            const value = this.tempCibIncome;

            // For Gross commission type
            if (this.quote.K_CommissionType === 'Gross') {
                const totalAllowed = Number(this.quote.K_InsurerCommission || 0);

                // Check if value exceeds total allowed
                if (value > totalAllowed) {
                    // Only update the entered value and its GST
                    this.$set(this.quote, "K_CibIncome", value);
                    this.$set(this.quote, "K_CibIncomeGst", this.calculateGst(value));

                    // Calculate just the percentages of the current values
                    const cibPercent = this.calculatePercent(value, totalAllowed);
                    const arPercent = this.calculatePercent(this.quote.K_ArIncome || 0, totalAllowed);
                    this.$set(this.quote, "K_CibIncomePercent", cibPercent);
                    this.$set(this.quote, "K_ArIncomePercent", arPercent);
                    return;
                }

                // If valid, proceed with complementary calculations
                const arIncome = totalAllowed - value;

                // Update all values
                this.$set(this.quote, "K_CibIncome", value);
                this.$set(this.quote, "K_CibIncomeGst", this.calculateGst(value));
                this.$set(this.quote, "K_ArIncome", arIncome);
                this.$set(this.quote, "K_ArIncomeGst", this.calculateGst(arIncome));

                // Calculate and update percentages
                const cibPercent = this.calculatePercent(value, totalAllowed);
                const arPercent = this.calculatePercent(arIncome, totalAllowed);
                this.$set(this.quote, "K_CibIncomePercent", cibPercent);
                this.$set(this.quote, "K_ArIncomePercent", arPercent);

            } else {
                // For Net commission type
                const totalAllowed = Number(this.quote.K_BrokerFee || 0);
                const arIncome = totalAllowed - value;

                this.$set(this.quote, "K_CibIncome", value);
                this.$set(this.quote, "K_CibIncomeGst", this.calculateGst(value));
                this.$set(this.quote, "K_ArIncome", arIncome);
                this.$set(this.quote, "K_ArIncomeGst", this.calculateGst(arIncome));

                const cibPercent = this.calculatePercent(value, totalAllowed);
                const arPercent = this.calculatePercent(arIncome, totalAllowed);
                this.$set(this.quote, "K_CibIncomePercent", cibPercent);
                this.$set(this.quote, "K_ArIncomePercent", arPercent);
            }
        },

        calculateGst(currencyValue) {
            if (!_.isNil(currencyValue)) {
                return this.round(Number(currencyValue) * 0.1);
            }
            return null;
        },

        calculatePercent(value, parentValue) {
            if (!_.isNil(value) && !_.isNil(parentValue) && Number(parentValue) !== 0) {
                const percent = (Number(value) * 100) / Number(parentValue);
                return this.round(percent);
            }
            return 0;
        },

        calculatePercentAmount(currencyValue, percent) {
            if (!_.isNil(currencyValue) && !_.isNil(percent)) {
                const amount = (Number(currencyValue) * Number(percent)) / 100;
                return this.round(amount);
            }
            return 0;
        },

        calculateSum: function (value1, value2) {
            if (!_.isNil(value1) && !_.isNil(value2)) {
                return this.round(Number(value1) + Number(value2));
            }
            return null;
        },

        round: function (number) {
            if (!_.isNil(number)) {
                // Use the passed number parameter instead of undefined currencyValue
                return Math.round(Number(number) * 100 + Number.EPSILON) / 100;
            }
            return 0;
        },

        recalculateIncomes() {
            if (this.quote.K_CommissionType === "Gross") {
                // AR Income is ArIncomePercent% of Insurer Commission
                const arIncome = this.calculatePercentAmount(
                    this.quote.K_InsurerCommission || 0,
                    this.quote.K_ArIncomePercent || 0
                );
                this.$set(this.quote, "K_ArIncome", arIncome);
                this.$set(
                    this.quote,
                    "K_ArIncomeGst",
                    this.calculateGst(arIncome)
                );

                // CIB Income is CibIncomePercent% of Insurer Commission
                const cibIncome = this.calculatePercentAmount(
                    this.quote.K_InsurerCommission || 0,
                    this.quote.K_CibIncomePercent || 0
                );
                this.$set(this.quote, "K_CibIncome", cibIncome);
                this.$set(
                    this.quote,
                    "K_CibIncomeGst",
                    this.calculateGst(cibIncome)
                );
            } else {
                // For Net, calculate based on Broker Fee
                const totalIncome = this.quote.K_BrokerFee || 0;

                const arIncome = this.calculatePercentAmount(
                    totalIncome,
                    this.quote.K_ArIncomePercent || 0
                );
                this.$set(this.quote, "K_ArIncome", arIncome);
                this.$set(
                    this.quote,
                    "K_ArIncomeGst",
                    this.calculateGst(arIncome)
                );

                const cibIncome = this.calculatePercentAmount(
                    totalIncome,
                    this.quote.K_CibIncomePercent || 0
                );
                this.$set(this.quote, "K_CibIncome", cibIncome);
                this.$set(
                    this.quote,
                    "K_CibIncomeGst",
                    this.calculateGst(cibIncome)
                );
            }
        },
    },

    watch: {
        isInvalid: {
            immediate: true,
            handler(newValue) {
                this.$emit('validation-changed', newValue);
            }
        },

        totalCibIncome: {
            handler(newValue) {
                this.$emit('totalCibIncomeChanged', newValue, this.totalCibIncomeGst);
            },
            immediate: true
        }
    },

};
</script>
