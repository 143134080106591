<template>
  <ValidationObserver ref="wizardPage6" id="wizardPage6" name="wizardPage6">
    <div v-if="appData">
      <instructions :instructions-text="instructions" />

      <insurer-quote-table
        class="mt-5"
        :app-data="appData"
        :show-finalisation-view="true"
        :is-read-only="isReadOnly || appData.statusFlags.quoteAccepted"
        v-on="$listeners"
      >
      </insurer-quote-table>

      <!-- Hidden input to validate if a quote has been accepted -->
      <ValidationProvider
        :rules="{ 'required-action': { allowFalse: false } }"
        name="quoteAccepted"
        v-slot="{ errors }"
      >
        <input type="hidden" v-model="appData.statusFlags.quoteAccepted" />
        <span class="text-danger">{{
          errors[0]
            ? "Please accept one of the quotes as per client's choice."
            : ""
        }}</span>
      </ValidationProvider>

      <div v-if="appData.statusFlags.quoteAccepted">
        <button
          class="btn btn-primary mt-10"
          @click.prevent="launchMailToLink(insurerConfirmationMailToLink)"
          :disabled="
            isReadOnly || appData.statusFlags.quoteAcceptanceSentToInsurer
          "
        >
          <i class="fa fa-envelope fa-md"></i>
          Send Confirmation to Insurer
        </button>

        <ValidationProvider
          :rules="{ 'required-action': { allowFalse: false } }"
          name="appData.statusFlags.quoteAcceptanceSentToInsurer"
          v-slot="{ errors }"
        >
          <div class="row mt-5">
            <span
              class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
            >
              <label
                v-show="!localStatusFlags.quoteAcceptanceSentToInsurer.saving"
              >
                <input
                  type="checkbox"
                  v-model="localStatusFlags.quoteAcceptanceSentToInsurer.value"
                  @change="flagUpdated('quoteAcceptanceSentToInsurer')"
                  :disabled="
                    isReadOnly || appData.statusFlags.readyForInvoicing
                  "
                />
                <span></span>
              </label>
              <b-spinner
                variant="primary"
                v-if="localStatusFlags.quoteAcceptanceSentToInsurer.saving"
              ></b-spinner>
            </span>
            <label class="col-form-label font-size-h5 ml-2">
              I confirm that I have sent the confirmation email to the insurer.
            </label>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div
        v-if="appData.statusFlags.quoteAcceptanceSentToInsurer"
        class="mt-10"
      >
        <Document
          document-name="New Business Letter"
          :document-data="appData.documents.nbLetter"
          :mailto-link="nbLetterMailToLink"
          :on-create-document="createNBLetter"
          :processing-indicator="creatingNBLetter"
          :downloadEnabled="false"
          :is-read-only="isReadOnly || appData.statusFlags.nbLetterSent"
          :isShowTitle="false"
        >
        </Document>

        <!-- Hidden input to validate if the user created the nb letter -->
        <ValidationProvider
          :rules="{ 'required-action': { allowFalse: false } }"
          name="appData.documents.nbLetter.id"
          v-slot="{ errors }"
        >
          <input type="hidden" v-model="appData.documents.nbLetter.id" />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <div v-if="appData.documents.nbLetter.view_url">
          <ValidationProvider
            :rules="{ 'required-action': { allowFalse: false } }"
            name="appData.statusFlags.nbLetterSent"
            v-slot="{ errors }"
          >
            <div class="row mt-10">
              <span
                class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
              >
                <label>
                  <input
                    type="checkbox"
                    v-model="appData.statusFlags.nbLetterSent"
                    :disabled="
                      isReadOnly || appData.statusFlags.readyForInvoicing
                    "
                    @change="
                      $emit('updateServerTransactionStatus', 'nbLetterSent')
                    "
                  />
                  <span></span>
                </label>
              </span>
              <label class="col-form-label font-size-h5 ml-2">
                I confirm that I have sent the New Business Letter to the
                client.
              </label>
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>

      <div class="mt-10" v-if="appData.statusFlags.nbLetterSent">
        <div>
          <h4>Special Invoicing Instructions</h4>
          <div class="form-group col-12 mt-5 pl-0">
            <ValidationProvider
              rules="max:2000"
              name="specialInvoicingInstructions"
              v-slot="{ errors }"
            >
              <textarea
                class="form-control min-rows"
                v-model="appData.transaction.K_SpecialInvoicingInstructions"
                :disabled="isReadOnly || appData.statusFlags.readyForInvoicing"
              >
              </textarea>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <h4 class="font-weight-bold">
          ONCE CONFIRMED BELOW, YOU WILL NOT BE ABLE TO MAKE ANY FURTHER CHANGES
          TO THIS NEW BUSINESS.
        </h4>
      </div>

      <div v-if="appData.statusFlags.nbLetterSent">
        <ValidationProvider
          :rules="{ 'required-action': { allowFalse: false } }"
          name="appData.statusFlags.readyForInvoicing"
          v-slot="{ errors }"
        >
          <div class="row mt-10">
            <span
              class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
            >
              <label>
                <input
                  type="checkbox"
                  v-model="appData.statusFlags.readyForInvoicing"
                  :disabled="isReadOnly"
                  @change="saveInvoicingInstructions"
                />
                <span></span>
              </label>
            </span>
            <label class="col-form-label font-size-h5 ml-2">
              I confirm that this New Business is ready to be submitted for
              invoicing.
            </label>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </ValidationObserver>
</template>

<style>
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px); 
}
</style>

<script>
import Instructions from "../../../common/Instructions";
import InsurerQuoteTable from "../../../common/InsurerQuoteTable";
import Document from "../../../common/Document";

export default {
  name: "FinalisationPage",

  components: {
    Instructions,
    InsurerQuoteTable,
    Document,
  },

  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      localStatusFlags: {
        quoteAcceptanceSentToInsurer: {
          value: this.appData.statusFlags.quoteAcceptanceSentToInsurer,
          saving: false,
        },
      },

      creatingNBLetter: false,
    };
  },

  methods: {
    flagUpdated: function (flagName) {
      let flagData = this.localStatusFlags[flagName];
      if (flagData) {
        flagData.saving = true;

        this.$newBusinessDataService
          .updateTransactionStatus(flagName, flagData.value)
          .then(({ data }) => {
            let updatedStatus = data.result;
            if (updatedStatus) {
              // Update appData flags
              this.appData.statusFlags[flagName] = flagData.value;

              // Update transaction status
              let txn = this.appData.transaction;
              txn.K_Status = updatedStatus.K_Status;
              txn.K_StatusDetails = updatedStatus.K_StatusDetails;
              txn.K_NextStep = updatedStatus.K_NextStep;
              txn.K_DueInWeek = updatedStatus.K_DueInWeek;
              txn.K_WeekStartDate = updatedStatus.K_WeekStartDate;

              // Fire event to update the global transaction status on the UI
              this.$emit("statusUpdated");

              this.$emit("updateWizardAppDataString");
            }

            flagData.saving = false;
          })
          .catch((error) => {
            // Reset to original value
            flagData.value = !flagData.value;
            flagData.saving = false;
            this.$emit("handleError", error);
          });
      }
    },

    createNBLetter: function () {
      this.creatingNBLetter = true;

      this.$newBusinessDataService
        .createNBLetter()
        .then(({ data }) => {
          if (data.result) {
            let nbLetterInfo = data.result;
            this.appData.documents.nbLetter = {
              id: nbLetterInfo.id,
              view_url: nbLetterInfo.K_NewBusinessLetterLink,
            };
            this.$emit("updateWizardAppDataString");
          }
          this.creatingNBLetter = false;
        })
        .catch((error) => {
          this.creatingNBLetter = false;
          this.$emit("handleError", error);
        });
    },

    launchMailToLink: function (link) {
      if (link) {
        window.open(link);
      }
    },

    saveInvoicingInstructions: function () {
      if (!this.appData.statusFlags.readyForInvoicing) {
        this.flagUpdated("readyForInvoicing");
        return;
      }

      let txnData = {
        K_SpecialInvoicingInstructions:
          this.appData.transaction.K_SpecialInvoicingInstructions,
      };
      this.$newBusinessDataService
        .updateTransactionData(txnData)
        .then((/*{ data }*/) => {
          // Update the transaction status (also updates wizardAppDataString)
          this.$emit("updateServerTransactionStatus", "readyForInvoicing");
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },
  },

  computed: {
    instructions: function () {
      return "Please accept one of the quotes provided to the client as per the Broker Advice. If the client accepts one of the proposed options directly from the Broker Advice, this page will be updated automatically.";
    },

    nbLetterMailToLink: function () {
      let mailTo = null;
      let email = "test@test.com";
      mailTo = `mailto:${email}`;

      // let policyNumber = "06SI998515";
      let subject = `New Business Letter for Strata Plan #3725 - 445 Georage Street, Sydney NSW`;
      subject = encodeURIComponent(subject);
      mailTo = `${mailTo}?subject=${subject}`;

      // Add email body
      let emailBody =
        `Hi James` +
        `Please find attached a copy of your New Business Letter relating to SP3275 for George street, sydney NSW. This Policy is now effect` +
        `If you have any questions or would like to discuss, do not hesitate  to contact me Thank You.%0D%0A %0D%0A` +
        `Kind regards,%0D%0A %0D%0A` +
        `Hayley Clarke %0D%0A`;
      mailTo = `${mailTo}&body=${emailBody}`;

      return mailTo;
    },

    insurerConfirmationMailToLink: function () {
      let mailTo = null;
      let email = "test@test.com";
      mailTo = `mailto:${email}`;

      let policyNumber = "06SI998515";
      let subject = `CIB Quote Confirmation - Policy #${policyNumber}`;
      subject = encodeURIComponent(subject);
      mailTo = `${mailTo}?subject=${subject}`;

      // Add email body
      let emailBody =
        `Dear CHU %0D%0A %0D%0A` +
        `We whould like to confirm acceptance of your recent quote for the policy ${policyNumber} %0D%0A %0D%0A` +
        `Kind regards,%0D%0A %0D%0A` +
        `Hayley Clarke %0D%0A` +
        `Team Leader`;
      mailTo = `${mailTo}&body=${emailBody}`;

      return mailTo;
    },
  },
};
</script>
