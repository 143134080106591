<template>
    <ValidationObserver ref="updateQuoteDialog">
        <div v-if="quote">
            <b-card border-variant="primary" body-class="px-4 py-4 diag">
                <div class="row justify-content-center">
                    <div class="col-3">
                        <table class="table table-borderless">
                            <tr>
                                <th colspan="2">
                                    Insurer Outcome<span class="text-danger">*</span>
                                </th>
                            </tr>
                            <tr v-for="(item, i) in insurerOutcomes" :key="i">
                                <td width="5%">
                                    <label class="radio">
                                        <input type="radio" name="insurerOutcome" :disabled="isReadOnly" :value="item"
                                            v-model="quote.K_InsurerOutcome" />
                                        <span></span>
                                    </label>
                                </td>
                                <td>{{ item }}</td>
                            </tr>
                        </table>
                        <!-- Hidden input to validate if the Insurer Outcome has been selected -->
                        <ValidationProvider rules="required" name="quote.K_InsurerOutcome" v-slot="{ errors }">
                            <input type="hidden" v-model="quote.K_InsurerOutcome" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col-2">
                        <table class="table table-borderless">
                            <tr>
                                <th colspan="2">
                                    Interface Type<span class="text-danger">*</span>
                                </th>
                            </tr>
                            <tr v-for="(item, i) in interfaceTypes" :key="i">
                                <td width="5%">
                                    <label class="radio">
                                        <input type="radio" name="interfaceType" :value="item" v-model="quote.K_Interface"
                                            :disabled="isReadOnly" />
                                        <span></span>
                                    </label>
                                </td>
                                <td>{{ item }}</td>
                            </tr>
                        </table>
                        <!-- Hidden input to validate if the Interface Type has been selected -->
                        <ValidationProvider rules="required" name="quote.K_Interface" v-slot="{ errors }">
                            <input type="hidden" v-model="quote.K_Interface" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </b-card>

            <b-card v-if="quote.K_InsurerOutcome === 'Non-Binding Quote'" border-variant="primary" body-class="px-4 py-4"
                class="mt-5">
                <h6 class="font-weight-bold text-dark">
                    <span class="ml-2" style="vertical-align: center">
                        Quote Subjectivities
                    </span>
                    <textarea class="form-control mt-3 min-rows" v-model="quote.K_Subjectivities" :disabled="isReadOnly"
                        style="width: 100%;" placeholder="If applicable" maxlength="2000"></textarea>
                    <small class="form-text text-muted">Max 2,000 characters</small>
                </h6>
                <h6 class="font-weight-bold text-dark mt-4">
                    <span class="ml-2" style="vertical-align: center">
                        Endorsements / Conditions
                    </span>
                    <textarea class="form-control mt-3 min-rows" v-model="quote.K_EndorsementsConditions"
                        :disabled="isReadOnly" style="width: 100%;" placeholder="If applicable"
                        maxlength="32000"></textarea>
                    <small class="form-text text-muted">Max 32,000 characters</small>
                </h6>
            </b-card>

            <b-card v-if="quote.K_InsurerOutcome === 'Quote Received'" border-variant="primary" body-class="px-4 py-4"
                class="mt-5">
                <h6 class="font-weight-bold text-dark">
                    <span class="ml-2" style="vertical-align: center">
                        Quote Reference No.
                    </span>
                    <input type="text" class="form-control mt-3" v-model="quote.K_QuoteReference" :disabled="isReadOnly"
                        style="width: 20%;" placeholder="If applicable" maxlength="255">
                </h6>
                <h6 class="font-weight-bold text-dark mt-4">
                    <span class="ml-2" style="vertical-align: center">
                        Endorsements / Conditions
                    </span>
                    <textarea class="form-control mt-3 min-rows" v-model="quote.K_EndorsementsConditions"
                        :disabled="isReadOnly" style="width: 100%;" placeholder="If applicable"
                        maxlength="32000"></textarea>
                    <small class="form-text text-muted">Max 32,000 characters</small>
                </h6>
            </b-card>

            <b-card v-if="quote.K_InsurerOutcome === 'Declined to Quote'" border-variant="primary" body-class="px-4 py-4"
                class="mt-5">
                <h6 class="font-weight-bold text-dark">
                    <span class="ml-2" style="vertical-align: center">Enter Decline Reason</span>
                </h6>

                <div class="col-12 mt-4">
                    <ValidationProvider rules="required" name="quote.K_DeclineReason" v-slot="{ errors }">
                        <b-form-radio-group stacked :disabled="isReadOnly" v-model="quote.K_DeclineReason">
                            <b-form-radio value="Due to Claims History">Due to Claims History</b-form-radio>
                            <b-form-radio value="Due to Construction">Due to Construction</b-form-radio>
                            <b-form-radio value="Due to Cladding / Asbestos / WH&S">Due to Cladding / Asbestos /
                                WH&S</b-form-radio>
                            <b-form-radio value="Due to Defects">Due to Defects</b-form-radio>
                            <b-form-radio value="Due to Capacity of Insurer">Due to Capacity of Insurer</b-form-radio>
                            <b-form-radio value="Other">Other</b-form-radio>
                        </b-form-radio-group>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="form-group col-12 mt-5" v-if="quote.K_DeclineReason === 'Other'">
                    Enter Other Reason
                    <ValidationProvider rules="required|max:255" name="quote.K_DeclineDetails" v-slot="{ errors }">
                        <textarea class="form-control min-rows" v-model="quote.K_DeclineDetails"
                            :disabled="isReadOnly"></textarea>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </b-card>

            <b-card v-if="quote.K_InsurerOutcome === 'Quote Received' ||
                quote.K_InsurerOutcome === 'Non-Binding Quote'
                " border-variant="primary" body-class="px-4 py-4" class="mt-5">

                <!-- TODO: confirm implementation with CIB -->
                <!-- <div class="row">
                    <div class="col-1"></div>
                    <div class="form-group col-2">
                        <label class="col-form-label font-weight-bold">Product<span class="text-danger">*</span></label>
                    </div>
                    <div class="form-group col-6">
                        <ValidationProvider rules="required" name="quote.K_Product" v-slot="{ errors }">
                            <v-autocomplete v-model="quote.K_Product" :items="insurerProducts" item-text="Name"
                                item-value="id" :disabled="isReadOnly" dense clearable
                                :label="quote.K_Product ? '' : 'Start typing to search products'" return-object
                                class="custom-autocomplete" :menu-props="{ offsetY: true }">
                                <template v-slot:selection="{ item }">
                                    {{ item.Name }}
                                </template>
                                <template v-slot:item="{ item }">
                                    {{ item.Name }}
                                </template>
                            </v-autocomplete>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div> -->


                <div class="row">
                    <div class="col-1"></div>
                    <div class="form-group col-2">
                        <label class="col-form-label font-weight-bold">From Date</label>
                    </div>
                    <div class="form-group col-3">
                        <ValidationProvider rules="required" name="quote.K_FromDate" v-slot="{ errors }">
                            <b-form-datepicker class="form-control" :locale="'en-AU'" v-model="quote.K_FromDate"
                                :disabled="isReadOnly" :date-format-options="{
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }">
                            </b-form-datepicker>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="form-group col-2">
                        <label class="col-form-label font-weight-bold float-right">To Date</label>
                    </div>
                    <div class="form-group col-3">
                        <ValidationProvider rules="required" name="quote.K_ToDate" v-slot="{ errors }">
                            <b-form-datepicker class="form-control" :locale="'en-AU'" v-model="quote.K_ToDate"
                                :disabled="isReadOnly" :date-format-options="{
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }">
                            </b-form-datepicker>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>

                <declaration-form :formData="quote.declarationData.sumInsuredData" :configuration="sumInsuredConfig"
                    :is-read-only="isReadOnly" @delete-item="deleteItem" />
                <declaration-form :formData="quote.declarationData.excessData" :configuration="excessesConfig"
                    :is-read-only="isReadOnly" @delete-item="deleteItem" />

                <b-card border-variant="" body-class="px-2 py-2" class="mt-5 mb-5">
                    <h4 class="fs-2x fw-bolder mb-0 text-center py-4">
                        Financial Details
                    </h4>
                    <div>
                        <div class="form-group row mt-5">
                            <label class="col-2 col-form-label text-right">Base Premium</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="basePremium" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="quote.K_BasePremium" @input="fieldChanged"
                                        :disabled="isReadOnly" :allow-negative="true" />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">ESL Levies</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="eslLevies" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="quote.K_EslLevies" @input="fieldChanged"
                                        :disabled="isReadOnly" :allow-negative="true" />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">GST</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="gst" v-slot="{ errors }">
                                    <div class="input-group">
                                        <currency-input class="form-control" v-model="quote.K_Gst" :allow-negative="true"
                                            @input="calculateTotals" :disabled="isReadOnly" />
                                    </div>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right">Stamp Duty</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="stampDuty" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="quote.K_StampDuty"
                                        @input="calculateTotals" :disabled="isReadOnly" :allow-negative="true" />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">Admin Fee (ex. GST)</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="adminFee" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="quote.K_AdminFee" @input="adminFeeChanged"
                                        :disabled="isReadOnly" :allow-negative="true" />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">Admin Fee GST</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="adminFeeGst" v-slot="{ errors }">
                                    <div class="input-group">
                                        <currency-input class="form-control" v-model="quote.K_AdminFeeGst"
                                            :allow-negative="true" @input="calculateTotals" :disabled="isReadOnly" />
                                    </div>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right font-weight-bold">Total Insurer Payable</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="totalInsurerPayable" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="quote.K_TotalInsurerPayable"
                                        :allow-negative="true" disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <fees-and-commission ref="feesAndCommission" :quote="quote" @brokerFeeChanged="calculateTotals"
                            @totalCibIncomeChanged="updateTotalCibIncome" :show-commissions="showFeesCommissions"
                            :is-read-only="isReadOnly" @validation-changed="handleFeesValidation"></fees-and-commission>

                        <b-card v-if="showFeesCommissions" body-class="px-3 py-3 pt-5" border-variant="info">
                            <div class="form-group row mb-0">
                                <label class="col-2 col-form-label text-right font-weight-bold">Total Premium
                                    Payable</label>
                                <div class="col-2">
                                    <ValidationProvider rules="required" name="totalPremiumPayable" v-slot="{ errors }">
                                        <currency-input class="form-control" v-model="quote.K_TotalPremiumPayable"
                                            :allow-negative="true" disabled />
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <label class="col-2 col-form-label text-right font-weight-bold">Total CIB Income</label>
                                <div class="col-2">
                                    <ValidationProvider rules="required" name="totalCibIncome" v-slot="{ errors }">
                                        <currency-input class="form-control" v-model="cibTotalIncome" :allow-negative="true"
                                            disabled />
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <label class="col-2 col-form-label text-right font-weight-bold">Total CIB Income GST</label>
                                <div class="col-2">
                                    <ValidationProvider rules="required" name="totalCibIncomeGst" v-slot="{ errors }">
                                        <div class="input-group">
                                            <currency-input class="form-control" v-model="cibTotalIncomeGst"
                                                :allow-negative="true" disabled />
                                            <!-- <div class="input-group-append">
                                                <button class="btn btn-outline-secondary px-1 mx-0 no-hover" type="button"
                                                    @click="adjustGst('up', 'K_CibIncomeGst')">+</button>
                                                <button class="btn btn-outline-secondary px-1 mx-0 no-hover" type="button"
                                                    @click="adjustGst('down', 'K_CibIncomeGst')">-</button>
                                            </div> -->
                                        </div>
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </b-card>
            </b-card>
        </div>
    </ValidationObserver>
</template>

<style>
.custom-autocomplete {
    width: 100%;
    font-size: 1rem !important;
}

/* Input text */
.v-input__slot input {
    font-size: 1rem !important;
}

/* Dropdown menu items */
.v-list-item__title {
    font-size: 1rem !important;
}

/* Selected text */
.v-select__selection {
    font-size: 1rem !important;
}

.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}

.no-hover:hover {
    background-color: inherit;
    border-color: inherit;
}
</style>

<script>
import _ from "lodash";
import DeclarationForm from "./DeclarationForm.vue";
import FeesAndCommission from "../common/FeesAndCommission";

export default {
    name: "UpdateQuoteDialog",
    components: { DeclarationForm, FeesAndCommission },
    props: {
        quote: Object,
        showFeesCommissions: {
            type: Boolean,
            default: true,
        },
        isReadOnly: Boolean,
    },
    data() {
        return {
            insurerProducts: [],
            dataSources: {
                excessData: this.quote.declarationData.excessData,
                sumInsuredData: this.quote.declarationData.sumInsuredData
            },
            sumInsuredConfig: {
                title: "Sums Insured",
                currentTitle: "Requested Value",
                renewalTitle: "Quoted Value",
                allowAddition: true,
                allowDeletion: true,
                addButtonTitle: "Add Sum Insured",
            },
            excessesConfig: {
                title: "Excesses",
                currentTitle: "Requested Value",
                renewalTitle: "Quoted Value",
                allowAddition: true,
                allowDeletion: true,
                addButtonTitle: "Add Excess",
            },
            financialDataCurrencyConfig: {
                allowNegative: false,
                distractionFree: false,
                precision: 2,
            },
            currencyConfig: {
                allowNegative: false,
                distractionFree: false,
                precision: 0,
            },
            insurerOutcomes: [
                "Quote Received",
                "Exceeds SLA",
                "Non-Binding Quote",
                "Declined to Quote",
            ],
            interfaceTypes: ["Manual", "SCTP", "Sunrise"],
            isFeesInvalid: false,
        };
    },

    mounted() {
        if (this.quote) {
            this.$nextTick(() => {
                this.$emit('totalCibIncomeChanged', this.cibTotalIncome, this.cibTotalIncomeGst);
            });
        };
        this.getInsurerProducts();
    },

    methods: {
        handleFeesValidation(isInvalid) {
            this.isFeesInvalid = isInvalid;
            this.$emit('validation-changed', isInvalid);
        },

        getInsurerProducts() {
            this.$renewalDataService.getInsurerProducts()
                .then(({ data }) => {
                    if (data.result) {
                        this.insurerProducts = data.result;
                    }
                })
                .catch((error) => {
                    // Handle error appropriately
                    console.error('Error fetching insurer products:', error);
                });
        },

        updateTotalCibIncome(totalCibIncome, totalCibIncomeGst) {
            this.cibTotalIncome = totalCibIncome;
            this.cibTotalIncomeGst = totalCibIncomeGst;

            // Save to quote object for persistence
            this.$set(this.quote, 'K_CibTotalIncome', totalCibIncome);
            this.$set(this.quote, 'K_CibTotalIncomeGst', totalCibIncomeGst);
        },

        calculateTotals: function () {
            // Calculate Total Insurer Payable
            let totalInsurerPayable =
                Number(this.quote.K_BasePremium || 0) +
                Number(this.quote.K_EslLevies || 0) +
                Number(this.quote.K_StampDuty || 0) +
                Number(this.quote.K_Gst || 0) +
                Number(this.quote.K_AdminFee || 0) +
                Number(this.quote.K_AdminFeeGst || 0);
            this.quote.K_TotalInsurerPayable = this.round(totalInsurerPayable);

            // Calculate Total Premium Payable
            let totalPremiumPayable =
                this.quote.K_TotalInsurerPayable +
                (this.quote.K_BrokerFee || 0) +
                (this.quote.K_BrokerFeeGst || 0);
            this.quote.K_TotalPremiumPayable = this.round(totalPremiumPayable);
            return totalInsurerPayable;
        },

        deleteItem: function (item, formData) {
            let index = formData.findIndex(dataItem => dataItem === item);
            if (index !== -1) {
                formData.splice(index, 1);
            }
        },

        // adjustGst: function (direction, fieldName) {
        //     const adjustment = 0.01;
        //     if (direction === 'up') {
        //         this.manualGstAdjustment += adjustment;
        //     } else {
        //         this.manualGstAdjustment -= adjustment;
        //     }

        //     // Recalculate the total CIB Income GST
        //     this.recalculateTotalCibIncomeGst();
        // },

        // recalculateTotalCibIncomeGst() {
        //     const baseGst = this.calculateGst(this.cibTotalIncome);
        //     const adjustedGst = this.round(baseGst + this.manualGstAdjustment);
        //     this.cibTotalIncomeGst = adjustedGst;

        //     // Emit the change to the FeesAndCommission component
        //     this.$refs.feesAndCommission.updateCibIncomeGst(adjustedGst);
        // },

        basePremiumChanged: function () {
            this.$set(
                this.quote,
                "K_Gst",
                this.calculateGst(this.quote.K_BasePremium)
            );
            this.calculateTotals();
        },

        eslFslChanged: function () {
            this.$set(
                this.quote,
                "K_Gst",
                this.calculateGst(this.quote.K_EslLevies)
            );
            this.calculateTotals();
        },

        adminFeeChanged: function () {
            this.$set(
                this.quote,
                "K_AdminFeeGst",
                this.calculateGst(this.quote.K_AdminFee)
            );
            this.calculateTotals();
        },

        calculateGst: function (currencyValue) {
            if (!_.isNil(currencyValue)) {
                return this.round(Number(currencyValue) * 0.1);
            }
            return null;
        },

        fieldChanged: function () {
            const totalValue = Number(this.quote.K_BasePremium) + Number(this.quote.K_EslLevies);
            const gstValue = this.calculateGst(totalValue);
            this.$set(this.quote, "K_Gst", gstValue);
            this.calculateTotals();
        },

        round(number) {
            if (!_.isNil(number)) {
                return Math.round(Number(number) * 100 + Number.EPSILON) / 100;
            }
            return 0;
        },
    },

    computed: {
        cibTotalIncome: {
            get() {
                if (!this.quote) return 0;

                // Calculate based on commission type
                if (this.quote.K_CommissionType === "Gross") {
                    // For Gross: CIB Income + Broker Fee
                    const total = this.round(
                        (this.quote.K_CibIncome || 0) +
                        (this.quote.K_BrokerFee || 0)
                    );
                    // Set both values to ensure consistency
                    this.$set(this.quote, 'K_TotalCibIncome', total);
                    this.$set(this.quote, 'K_CibTotalIncome', total);
                    return total;
                } else {
                    // For Net: Just use CIB Income
                    const total = this.quote.K_CibIncome || 0;
                    // Set both values to ensure consistency
                    this.$set(this.quote, 'K_TotalCibIncome', total);
                    this.$set(this.quote, 'K_CibTotalIncome', total);
                    return total;
                }
            },
            set(value) {
                if (this.quote) {
                    this.$set(this.quote, 'K_TotalCibIncome', value);
                    this.$set(this.quote, 'K_CibTotalIncome', value);
                }
            }
        },
        cibTotalIncomeGst: {
            get() {
                if (!this.quote) return 0;

                // Calculate based on commission type
                if (this.quote.K_CommissionType === "Gross") {
                    // For Gross: CIB Income GST + Broker Fee GST
                    const totalGst = this.round(
                        (this.quote.K_CibIncomeGst || 0) +
                        (this.quote.K_BrokerFeeGst || 0)
                    );
                    // Set both values to ensure consistency
                    this.$set(this.quote, 'K_TotalCibIncomeGst', totalGst);
                    this.$set(this.quote, 'K_CibTotalIncomeGst', totalGst);
                    return totalGst;
                } else {
                    // For Net: Just use CIB Income GST
                    const totalGst = this.quote.K_CibIncomeGst || 0;
                    // Set both values to ensure consistency
                    this.$set(this.quote, 'K_TotalCibIncomeGst', totalGst);
                    this.$set(this.quote, 'K_CibTotalIncomeGst', totalGst);
                    return totalGst;
                }
            },
            set(value) {
                if (this.quote) {
                    this.$set(this.quote, 'K_TotalCibIncomeGst', value);
                    this.$set(this.quote, 'K_CibTotalIncomeGst', value);
                }
            }
        },
    },

    watch: {
        'quote.K_CommissionType'() {
            this.$nextTick(() => {
                // This will trigger the computed property to recalculate
                this.$emit('totalCibIncomeChanged', this.cibTotalIncome, this.cibTotalIncomeGst);
            });
        },
        'quote.K_CibIncome'() {
            this.$nextTick(() => {
                this.$emit('totalCibIncomeChanged', this.cibTotalIncome, this.cibTotalIncomeGst);
            });
        },
        'quote.K_BrokerFee'() {
            this.$nextTick(() => {
                this.$emit('totalCibIncomeChanged', this.cibTotalIncome, this.cibTotalIncomeGst);
            });
        }
    },
};
</script>
