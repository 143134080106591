<template>
  <div>
    <div class="form-group row">
      <label class="col-4 col-form-label">Select Reviewer</label>
      <div class="col-8">
        <ValidationProvider
          rules="required"
          name="reviewer"
          v-slot="{ errors }"
        >
          <b-form-select class="form-control" v-model="reviewData.reviewer">
            <b-form-select-option :value="null"
              >Select Reviewer</b-form-select-option
            >
            <b-form-select-option
              v-for="reviewer in reviewers"
              :key="reviewer.id"
              :value="reviewer"
            >
              {{ reviewer.full_name }}
            </b-form-select-option>
          </b-form-select>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-4 col-form-label">Comments for Reviewer</label>
      <div class="col-8">
        <ValidationProvider
          rules="required|max:1000"
          name="comments"
          v-slot="{ errors }"
        >
          <b-form-textarea
            class="form-control min-rows"
            v-model="reviewData.comments"
          ></b-form-textarea>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <span class="font-weight-bold">
      Please note that once a manager review has been requested, you won't be
      able to edit the Broker Advice until the review has been completed.
    </span>
  </div>
</template>

<style>
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px); 
}
</style>

<script>
export default {
  name: "RequestReview",

  props: {
    reviewData: Object,
    reviewers: Array,
  },
};
</script>
