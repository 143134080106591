<template>
    <ValidationObserver ref="insurerQuoteTable">
        <div>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th width="5%" class="text-center">No.</th>
                        <th width="30%" class="pl-8">Insurer</th>
                        <th width="15%" class="text-center">Total Premium Payable</th>
                        <th width="15%" class="text-center">Insurer Outcome</th>
                        <th class="text-center">Actions</th>
                        <th width="10%" class="text-center">Recommended</th>
                        <th width="10%" class="text-center">
                            {{ showFinalisationView ? "Quote Status" : "Ranking" }}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(quote, index) in sortedQuotes" :key="quote.id" :class="{
                        'draggable': isDraggable(quote),
                        'dragging': draggedQuote?.id === quote.id,
                        'drag-over': dragOverIndex === index
                    }" :draggable="isDraggable(quote)" @dragstart="handleDragStart($event, quote, index)"
                        @dragend="handleDragEnd" @dragover.prevent="handleDragOver($event, index)"
                        @drop.prevent="handleDrop($event, index)">

                        <td class="text-center align-middle">
                            <div class="d-flex align-items-center justify-content-center">
                                <i v-if="isDraggable(quote)" class="fas fa-grip-vertical grip-handle mr-2"
                                    :class="{ 'text-muted': isReadOnly }">
                                </i>
                                {{ index + 1 }}
                            </div>
                        </td>

                        <td class="pl-8 align-middle">
                            {{ quote.K_Insurer.name }}
                            <b-badge v-if="isCurrentInsurer(quote.K_Insurer)" variant="success" class="ml-2">
                                Current
                            </b-badge>
                        </td>

                        <td class="text-center align-middle">
                            <span v-if="quote.K_TotalPremiumPayable && appData.statusFlags.feesUpdated">
                                {{ quote.K_TotalPremiumPayable | currency }}
                            </span>
                        </td>

                        <td class="text-center align-middle">
                            <b-badge v-bind:variant="getInsurerOutcomeBadgeVariant(quote.K_InsurerOutcome)"
                                class="pt-3 pb-3 d-block badge-custom-width">
                                {{ quote.K_InsurerOutcome || "Update Pending" }}
                            </b-badge>
                        </td>

                        <td class="text-center align-middle">
                            <div class="d-flex justify-content-center align-items-center">
                                <!-- EDIT -->
                                <b-button v-if="!showFinalisationView" variant="link"
                                    @click.prevent="openUpdateQuoteDialog(quote.id)" v-b-tooltip.hover title="Edit"
                                    class="edit-icon-button" :disabled="isReadOnly">
                                    <i class="fas fa-pencil-alt"></i>
                                </b-button>

                                <!-- VIEW -->
                                <b-button v-if="showFinalisationView" variant="link"
                                    @click.prevent="openUpdateQuoteDialog(quote.id)" v-b-tooltip.hover title="View"
                                    class="edit-icon-button">
                                    <i class="fas fa-glasses"></i>
                                </b-button>

                                <!-- INCLUDE / EXCLUDE -->
                                <b-button variant="link"
                                    v-if="!quote.K_QuoteExcluded && !showFinalisationView && (quote.K_InsurerOutcome === 'Quote Received' || quote.K_InsurerOutcome === 'Non-Binding Quote')"
                                    v-b-tooltip.hover title="Included"
                                    @click.prevent="toggleIncludeExclude(quote.id, false)" class="visible-icon-button"
                                    :disabled="isReadOnly">
                                    <i class="fas fa-eye"></i>
                                </b-button>

                                <b-button variant="link"
                                    v-if="quote.K_QuoteExcluded || (!quote.K_QuoteExcluded && quote.K_InsurerOutcome !== 'Quote Received' && quote.K_InsurerOutcome !== 'Non-Binding Quote') && !showFinalisationView"
                                    v-b-tooltip.hover title="Excluded"
                                    :disabled="isReadOnly || quote.K_InsurerOutcome !== 'Quote Received' && quote.K_InsurerOutcome !== 'Non-Binding Quote'"
                                    @click.prevent="toggleIncludeExclude(quote.id, true)" class="hidden-icon-button">
                                    <i class="fas fa-eye-slash"></i>
                                </b-button>

                                <!-- ACCEPT -->
                                <b-button
                                    v-if="showFinalisationView && !isReadOnly && quote.K_InsurerOutcome === 'Quote Received'"
                                    @click.prevent="openAcceptQuoteDialog(quote.id)"
                                    size="sm"
                                    variant="outline-success"
                                    :disabled="needsDeclarationReview">
                                    Accept
                                </b-button>

                                <!-- DELETE -->
                                <b-button variant="link" @click.prevent="deleteItem(quote)" v-b-tooltip.hover title="Delete"
                                    class="trash-icon-button" :disabled="isReadOnly">
                                    <i class="fas fa-trash-alt"></i>
                                </b-button>
                            </div>
                        </td>

                        <td class="text-center align-middle">
                            <span v-if="showFinalisationView">
                                <span v-if="quote.K_RecommendedQuote">
                                    <b-badge class="mt-1" variant="primary">Yes</b-badge>
                                </span>
                            </span>
                            <span v-else>
                                <label class="checkbox-style"
                                    v-if="quote.K_InsurerOutcome === 'Quote Received' && !quote.saving">
                                    <input type="radio" :value="true" name="K_RecommendedQuote"
                                        v-model="quote.K_RecommendedQuote" @input="setRecommendedQuote(quote)"
                                        :disabled="isReadOnly" />
                                    <span class="checkmark"></span>
                                </label>
                                <b-spinner v-if="quote.saving" variant="primary" label="Please wait..."></b-spinner>
                            </span>
                        </td>

                        <td class="text-center align-middle">
                            <span v-if="!showFinalisationView" class="d-inline-block w-100">
                                <!-- Ranking Display -->
                                <div class="d-flex align-items-center justify-content-center">
                                    {{ quote.K_InsurerOutcome === 'Quote Received' ? (index + 1) : '-' }}
                                    <span v-if="hasUnsavedChanges && originalRankings[quote.id] !== index + 1"
                                        class="unsaved-change-indicator ml-2" v-b-tooltip.hover title="Unsaved change">
                                        *
                                    </span>
                                </div>
                            </span>
                            <!-- Quote Status Display -->
                            <span v-if="showFinalisationView && quote.K_QuoteStatus !== 'Created'">
                                <b-badge class="mt-1" :variant="getQuoteStatusBadgeVariant(quote.K_QuoteStatus)">
                                    {{ quote.K_QuoteStatus }}
                                </b-badge>
                            </span>
                        </td>
                    </tr>
                    <tr v-if="hasUnsavedChanges">
                        <td colspan="7" class="text-right">
                            <b-button variant="primary" size="sm" :disabled="isSaving" @click="saveRankingChanges"
                                class="save-rankings-btn my-2">
                                <span v-if="!isSaving">
                                    <i class="fas fa-save mr-1"></i>
                                    Save Order
                                </span>
                                <span v-else>
                                    <b-spinner small class="mr-1"></b-spinner>
                                    Saving...
                                </span>
                            </b-button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <b-modal ref="updateQuoteDialog" size="xl" :ok-title="isReadOnly ? 'Close' : 'Save'" scrollable no-close-on-esc
                no-close-on-backdrop hide-header-close
                :title="quoteBeingUpdated ? `Quote - ${quoteBeingUpdated.K_Insurer.name}` : ''" @ok="handleOk"
                :ok-disabled="isFormInvalid">
                <update-quote-dialog v-if="quoteBeingUpdated" :quote="quoteBeingUpdated"
                    :show-fees-commissions="appData.statusFlags.quotesUpdated" :is-read-only="isReadOnly"
                    @validation-changed="handleFeesValidation" />
                <div v-if="quoteError" class="text-danger mt-5">{{ quoteError }}</div>
            </b-modal>

            <b-modal ref="acceptQuoteDialog" size="xl" ok-title="Accept" scrollable no-close-on-esc no-close-on-backdrop
                hide-header-close :title="quoteBeingAccepted ? `Quote - ${quoteBeingAccepted.K_Insurer.name}` : ''"
                @ok="acceptQuote">
                <update-quote-dialog v-if="quoteBeingAccepted" :quote="quoteBeingAccepted" :show-fees-commissions="true"
                    :is-read-only="true" />
            </b-modal>

            <b-modal ref="deleteQuoteDialog" size="md" ok-title="Delete" ok-variant="danger" scrollable no-close-on-esc
                no-close-on-backdrop hide-header-close
                :title="quoteToDelete ? `Quote - ${quoteToDelete.K_Insurer.name} | ${quoteToDelete.K_InsurerOutcome}` : ''"
                @ok="deleteQuote">
                <div class="py-3">
                    Are you sure you wish to delete this quote?
                    <br><br>
                    <strong>This action cannot be undone.</strong>
                </div>
            </b-modal>
        </div>
    </ValidationObserver>
</template>

<script>
import _ from "lodash";
import UpdateQuoteDialog from "../common/UpdateQuoteDialog";

export default {
    components: { UpdateQuoteDialog },
    name: "InsurerQuoteTable",

    props: {
        appData: Object,
        isReadOnly: Boolean,
        showFinalisationView: Boolean,
    },

    data() {
        return {
            quoteError: null,
            quoteBeingUpdated: null,
            quoteBeingAccepted: null,
            quoteToDelete: null,
            isFeesInvalid: false,
            draggedQuote: null,
            dragOverIndex: null,
            dragStartIndex: null,
            saveTimeout: null,
            originalRankings: {}, // Store original rankings
            pendingRankings: {}, // Store pending changes
            isSaving: false,
        };
    },

    mounted() {
        this.reorderQuotes();
    },

    computed: {
      needsDeclarationReview() {
            const transaction = this.appData?.transaction;
            return transaction?.K_DeclarationFormStatus === 'Overridden' &&
                    transaction?.K_HasBeenSubmitted === true &&
                    !transaction?.K_DeclarationReviewed;
        },

        hasUnsavedChanges() {
            return !_.isEmpty(this.pendingRankings);
        },

        validQuoteRanks: function () {
            let quoteRanks = [];
            if (this.appData && Array.isArray(this.appData.quotes)) {
                let validQuotes = this.appData.quotes.filter((quote) => {
                    return quote.K_InsurerOutcome === "Quote Received";
                });

                for (let i = 0; i < validQuotes.length; i++) {
                    // quoteRanks.push(i + 1);
                    quoteRanks.push(i);
                }
            }
            return quoteRanks;
        },

        sortedQuotes() {
            if (!this.appData?.quotes) return [];

            // First sort by recommended (recommended quotes always first)
            // Then by ranking for non-recommended quotes
            // Finally by insurer outcome
            return _.orderBy(
                this.appData.quotes,
                [
                    quote => quote.K_RecommendedQuote ? 0 : 1, // Recommended first
                    'K_QuoteRanking',
                    'K_InsurerOutcome'
                ],
                ['asc', 'asc', 'desc']
            );
        },

        isFormInvalid() {
            return this.isFeesInvalid;
        },
    },

    methods: {
        refreshQuotes() {
            // Call parent's refreshQuotes method
            this.$emit('refreshQuotes');
        },

        isDraggable(quote) {
            return !this.isReadOnly &&
                !this.isSaving &&
                quote.K_InsurerOutcome === 'Quote Received' &&
                !quote.K_RecommendedQuote; // Can't drag recommended quotes
        },

        handleDragStart(event, quote, index) {
            if (this.isReadOnly) return
            this.draggedQuote = quote
            this.dragStartIndex = index
            event.dataTransfer.effectAllowed = 'move'
            // Add some transparency to dragged element
            event.target.style.opacity = '0.6'
        },

        handleDragEnd(event) {
            event.target.style.opacity = '1'
            this.draggedQuote = null
            this.dragOverIndex = null
        },

        handleDragOver(event, index) {
            if (!this.draggedQuote) return
            event.preventDefault();
            this.dragOverIndex = index
        },

        async handleDrop(event, newIndex) {
            if (!this.draggedQuote || newIndex === this.dragStartIndex) return;

            // Get current quotes array
            const quotes = [...this.sortedQuotes];

            // Don't allow dropping above recommended quotes
            const recommendedCount = quotes.filter(q => q.K_RecommendedQuote).length;
            if (newIndex < recommendedCount) {
                newIndex = recommendedCount;
            }

            // Remove quote from old position and insert at new position
            const oldIndex = quotes.findIndex(q => q.id === this.draggedQuote.id);
            quotes.splice(oldIndex, 1);
            quotes.splice(newIndex, 0, this.draggedQuote);

            // Create new pending rankings object to trigger reactivity
            const newPendingRankings = {};

            // Update rankings for all non-recommended quotes
            quotes.forEach((quote, idx) => {
                if (quote.K_InsurerOutcome === 'Quote Received' && !quote.K_RecommendedQuote) {
                    const newRank = idx + 1;
                    // Important: Set the actual ranking on the quote
                    quote.K_QuoteRanking = newRank;

                    // Track changes if different from original
                    if (this.originalRankings[quote.id] !== newRank) {
                        newPendingRankings[quote.id] = newRank;
                    }
                }
            });

            // Important: Update pendingRankings first
            this.pendingRankings = { ...newPendingRankings };

            // Then update the quotes array
            this.$set(this.appData, 'quotes', quotes);

            // Reset drag state
            this.draggedQuote = null;
            this.dragOverIndex = null;

            console.log('Pending Rankings after drop:', this.pendingRankings); // Debug line
        },

        handleFeesValidation(isInvalid) {
            this.isFeesInvalid = isInvalid;
        },

        closeDialog() {
            this.$refs.updateQuoteDialog.hide();
        },

        handleOk(bvModalEvt) {
            if (bvModalEvt && typeof bvModalEvt.preventDefault === 'function') {
                bvModalEvt.preventDefault(); // Prevent the modal from closing automatically
            }
            if (this.isReadOnly) {
                this.closeDialog();
            } else if (!this.isFormInvalid) { // Add this check
                this.saveQuoteDetails(bvModalEvt);
            }
        },

        reorderQuotes() {
            // Ensure appData and appData.quotes are valid
            if (this.appData && Array.isArray(this.appData.quotes)) {
                // Sort quotes
                this.appData.quotes = _.orderBy(this.appData.quotes, ['K_QuoteRanking'], ['asc']);
            }
        },

        deleteItem(quote) {
            if (this.isReadOnly) return;
            this.quoteToDelete = quote;
            this.$refs.deleteQuoteDialog.show();
        },

        deleteQuote() {
            if (this.quoteToDelete) {
                this.appData.quotes = this.appData.quotes.filter(q => q.id !== this.quoteToDelete.id);

                this.$emit('deleteQuote', this.quoteToDelete.id);

                // Reset the quoteToDelete variable
                this.quoteToDelete = null;
            }
            this.$refs.deleteQuoteDialog.hide();
        },


        toggleIncludeExclude(quoteId, isIncluded) {
            if (this.isReadOnly) return;
            const index = this.appData.quotes.findIndex(q => q.id === quoteId);
            if (index !== -1) {
                this.$set(this.appData.quotes[index], 'K_QuoteExcluded', !isIncluded);
                this.$emit("saveQuote", this.appData.quotes[index]);
            }
        },

        async saveRankingChanges() {
            if (_.isEmpty(this.pendingRankings) || this.isSaving) return;

            this.isSaving = true;
            try {
                const changes = Object.entries(this.pendingRankings)
                    .filter(([quoteId]) => {
                        const quote = this.sortedQuotes.find(q => q.id === quoteId);
                        return quote && !quote.K_RecommendedQuote;
                    })
                    .map(([quoteId, newRank]) => ({
                        quoteId,
                        ranking: newRank
                    }));

                // Emit the changes to parent
                await this.$emit('saveRankings', changes);

                // Update original rankings and clear pending
                this.originalRankings = {
                    ...this.originalRankings,
                    ...this.pendingRankings
                };
                this.pendingRankings = {};

            } catch (error) {
                console.error('Failed to save rankings:', error);
                this.$emit('handleError', error);
            } finally {
                this.isSaving = false;
            }
        },

        rankAlreadySelected: function (rank, currentQuoteId) {
            // Allow null/empty ranking
            if (rank === null || rank === '') {
                return false;
            }

            if (this.appData && Array.isArray(this.appData.quotes)) {
                return _.some(this.appData.quotes, (quote) => {
                    return quote.id !== currentQuoteId && quote.K_QuoteRanking === rank;
                });
            }
            return false;
        },

        openUpdateQuoteDialog: function (quoteId) {
            this.quoteError = null;

            this.$commonDataService
                .getQuoteDetails(quoteId)
                .then(({ data }) => {
                    this.quoteBeingUpdated = data.result;
                    this.$refs["updateQuoteDialog"].show();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        openAcceptQuoteDialog: function (quoteId) {
          if (this.needsDeclarationReview) {
                this.$bvModal.msgBoxOk('Please review the Declaration Form before accepting a quote.', {
                    title: 'Declaration Review Required',
                    headerClass: 'bg-warning',
                    bodyClass: 'text-dark',
                    okVariant: 'warning',
                    headerTextVariant: 'light',
                    centered: true
                });
                return;
            }

            this.quoteError = null;
            this.$commonDataService
                .getQuoteDetails(quoteId)
                .then(({ data }) => {
                    this.quoteBeingAccepted = data.result;

                    // Show 'Update Quote' modal
                    this.$refs["acceptQuoteDialog"].show();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        saveQuoteDetails: function (bvModalEvt) {
            if (this.isReadOnly) {
                return;
            }

            // Only prevent default if the event exists
            if (bvModalEvt && typeof bvModalEvt.preventDefault === 'function') {
                bvModalEvt.preventDefault();
            }
            this.quoteError = null;

            this.$refs.insurerQuoteTable.validate().then((isValid) => {
                if (isValid) {
                    // Quote is valid - close the dialog and save the quote
                    this.$emit("saveQuote", this.quoteBeingUpdated);
                    this.$refs["updateQuoteDialog"].hide();
                }
            });
        },

        setRecommendedQuote(quote) {
            if (this.isReadOnly) return;

            // When setting a quote as recommended:
            // 1. Update the quote's ranking to 1
            // 2. Shift other quotes down
            const quotes = [...this.sortedQuotes];
            quotes.forEach(q => {
                if (q.id === quote.id) {
                    q.K_QuoteRanking = 1;
                } else if (q.K_QuoteRanking <= quote.K_QuoteRanking) {
                    q.K_QuoteRanking++;
                }
            });

            this.$emit("setRecommendedQuote", quote);
        },

        acceptQuote: function (/* bvModalEvt */) {
            if (this.isReadOnly) {
                return;
            }
            this.$emit("acceptQuote", this.quoteBeingAccepted);
        },

        isCurrentInsurer: function (insurer) {
            let policyInsurerId = _.get(this.appData, "policy.K_Insurer.id");
            return policyInsurerId === insurer.id;
        },

        getInsurerOutcomeBadgeVariant: function (insurerOutcome) {
            if (insurerOutcome) {
                let outcome = insurerOutcome.toLowerCase();
                if (outcome.includes("quote received")) {
                    return "info";
                }
                else if (outcome.includes("binding")) {
                    return "success"
                }
                else {
                    return "warning";
                }
            }
            return "danger";
        },

        getQuoteStatusBadgeVariant: function (quoteStatus) {
            if (quoteStatus) {
                let outcome = quoteStatus.toLowerCase();
                if (outcome === "accepted by client") {
                    return "success";
                } else {
                    return "secondary";
                }
            }
            return "secondary";
        },

        onQuoteRankingSaved({ quoteId }) {
            const quote = this.appData.quotes.find(q => q.id === quoteId);
            if (quote) {
                this.$set(quote, 'saving', false);
            }
        },
    },

    watch: {
        'appData.quotes': {
            immediate: true,
            deep: true,
            handler(newQuotes) {
                if (newQuotes) {
                    // Only update originalRankings if we don't have any pending changes
                    if (_.isEmpty(this.pendingRankings)) {
                        const rankings = {};
                        newQuotes.forEach((quote, index) => {
                            if (quote.K_InsurerOutcome === 'Quote Received') {
                                rankings[quote.id] = quote.K_QuoteRanking || index + 1;
                            }
                        });
                        this.originalRankings = rankings;
                    }
                }
            }
        }
    },
};
</script>

<style scoped>
.edit-icon-button i {
    color: #007bff;
    opacity: 0.8;
}

.edit-icon-button:hover i {
    color: #007bff;
    opacity: 1;
}

.trash-icon-button i {
    color: #dc3545;
    opacity: 0.8;
}

.trash-icon-button:hover i {
    color: #dc3545;
    opacity: 1;
}

.visible-icon-button i {
    color: #07a651;
    opacity: 0.8;
}

.visible-icon-button:hover i {
    color: #07a651;
    opacity: 1;
}

.hidden-icon-button i {
    color: #a38b8e;
}

.badge-custom-width {
    width: 100%;
    margin: auto;
}

.input-group-sm .form-control,
.input-group-sm .input-group-text {
    font-size: 0.875rem;
    line-height: 1.5;
}

.small-icon {
    font-size: 0.875rem;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.input-group {
    flex-wrap: nowrap;
    width: 100%;
}

.input-group-append {
    flex-shrink: 0;
}

.input-group .form-control {
    min-width: 0;
    border-top-right-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
}

/* Override Bootstrap's default styles for the select element */
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.checkbox-style {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0;
}

.checkbox-style input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #adb5bd;
    border-radius: 3px;
}

.checkbox-style:hover input~.checkmark {
    background-color: #f8f9fa;
}

.checkbox-style input:checked~.checkmark {
    background-color: #007bff;
    border-color: #007bff;
}

.checkbox-style input:disabled~.checkmark {
    background-color: #e9ecef;
    border-color: #dee2e6;
    cursor: not-allowed;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-style input:checked~.checkmark:after {
    display: block;
}

.checkbox-style .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.draggable {
    cursor: move;
}

.dragging {
    opacity: 0.6;
    background: #f8f9fa;
}

.drag-over {
    border-bottom: 2px solid #007bff;
}

.grip-handle {
    cursor: move;
    color: #6c757d;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.grip-handle:hover {
    opacity: 1;
}

.saving-indicator {
    font-size: 0.75rem;
    color: #6c757d;
    animation: fadeInOut 1.5s ease-in-out infinite;
}

@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0.4;
    }

    50% {
        opacity: 1;
    }
}

tr:hover .grip-handle {
    opacity: 1;
}

.unsaved-change-indicator {
    color: #dc3545;
    font-weight: bold;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

.save-rankings-btn {
    font-size: 0.75rem;
    padding: 0.2rem 0.5rem;
}

.unsaved-change-indicator {
    color: #dc3545;
    font-weight: bold;
    font-size: 1.2em;
}
</style>
