<template>
    <ValidationObserver ref="wizardPage2" id="wizardPage2" name="wizardPage2">
        <div>
            <div v-if="appData" v-on="$listeners">
                <instructions :instructions-text="instructions" />

                <div class="form-group row mb-2 mt-8">
                    <label class="col-2 col-form-label">Declaration Contact</label>
                    <div class="col-4 font-weight-bold">
                        <ValidationProvider name="appData.transaction.uiDeclarationContact" v-slot="{ errors }">
                            <b-form-select v-model="selectedContact"
                                :disabled="isReadOnly || appData.statusFlags.declarationSent"
                                @change="saveDeclarationContact">
                                <b-form-select-option :value="null">Select a contact</b-form-select-option>
                                <b-form-select-option v-for="contact in contacts" :key="contact.id" :value="contact">
                                    {{ contact.Name }} - {{ contact.Email }}
                                </b-form-select-option>
                            </b-form-select>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>

                <Document :document-name="'Declaration Form'" :document-data="appData.documents.declarationForm"
                    :on-create-document="createDecForm"
                    :get-client-declaration-email-template="getClientDeclarationEmailTemplate"
                    :processing-indicator="creatingDeclarationForm"
                    :is-read-only="isReadOnly || appData.statusFlags.declarationSent" :recreate-enabled="true"
                    :is-email-disabled="false" :isSubmitted="false">
                </Document>

                <!-- Hidden input to validate if the user created the declaration form -->
                <ValidationProvider rules="required-action" name="appData.documents.declarationForm.id" v-slot="{ errors }">
                    <input type="hidden" v-model="appData.documents.declarationForm.id" />
                    <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <div v-if="appData.documents.declarationForm.view_url">
                    <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                        name="appData.statusFlags.declarationSent" v-slot="{ errors }">
                        <div class="row mt-10">
                            <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                                <label>
                                    <input type="checkbox" :disabled="isReadOnly"
                                        v-model="appData.statusFlags.declarationSent" @change="
                                            $emit('updateServerTransactionStatus', 'declarationSent')
                                            " />
                                    <span></span>
                                </label>
                            </span>
                            <label class="col-form-label font-size-h5 ml-2">
                                I confirm that I have sent the Declaration Form to the Strata
                                contact.
                            </label>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import Instructions from "../../../common/Instructions";
import Document from "../../../common/Document";

export default {
    name: "DeclarationPage",

    components: {
        Instructions,
        Document,
    },

    props: {
        appData: Object,
        refData: Object,
        isReadOnly: Boolean,
        getClientDeclarationEmailTemplate: {
            type: Function,
            required: false
        }
    },

    computed: {
        instructions: function () {
            return "Please create the Declaration Form and then send it to the Declaration Contact. You can change the Declaration Contact if you wish.";
        },

        selectedContact: {
            get() {
                return this.appData.transaction.uiDeclarationContact;
            },
            set(value) {
                this.$set(this.appData.transaction, 'uiDeclarationContact', value);
                this.saveDeclarationContact();
            }
        },
    },

    data() {
        return {
            creatingDeclarationForm: false,
            contacts: []
        };
    },

    async mounted() {
        this.contacts = await this.getStrataCompanyContacts();

        // If we have existing declaration contact details from CRM
        if (!this.appData.transaction.uiDeclarationContact &&
            this.appData.transaction.K_DeclarationContact) {
            const contactObj = {
                Name: this.appData.transaction.K_DeclarationContact,
                Email: this.appData.transaction.Email
            };
            this.$set(this.appData.transaction, 'uiDeclarationContact', contactObj);
        }

        // If no declaration contact set at all, default to primary policy contact
        if (!this.appData.transaction.uiDeclarationContact &&
            this.appData.policy.K_PrimaryPolicyContact) {
            const primaryContact = {
                Name: this.appData.policy.K_PrimaryPolicyContact.Name,
                Email: this.appData.policy.K_PrimaryPolicyContact.Email
            };
            this.$set(this.appData.transaction, 'uiDeclarationContact', primaryContact);
            await this.saveDeclarationContact();
        }
    },

    methods: {
        getStrataCompanyContacts: async function () {
            let contacts = [];

            // First add the primary policy contact if it exists
            if (this.appData?.policy?.K_PrimaryPolicyContact) {
                contacts.push(this.appData.policy.K_PrimaryPolicyContact);
            }

            // Then fetch and add other strata company contacts
            if (this.appData?.strataCompany?.id) {
                try {
                    const response = await this.$newBusinessDataService.getStrataCompanyContacts(this.appData.strataCompany.id);
                    if (response?.data?.result) {
                        const otherContacts = response.data.result.filter(contact =>
                            !this.appData?.policy?.K_PrimaryPolicyContact?.id ||
                            contact.id !== this.appData.policy.K_PrimaryPolicyContact.id
                        );

                        contacts = [...contacts, ...otherContacts].sort((a, b) =>
                            (a.Name || '').localeCompare(b.Name || '')
                        );
                    }
                } catch (error) {
                    console.error('Error fetching strata company contacts:', error);
                    this.$emit('handleError', error);
                }
            }

            return contacts;
        },

        // async saveDeclarationContact() {
        //     const decContact = this.appData.transaction?.uiDeclarationContact;
        //     if (!decContact) return;

        //     try {
        //         // Just update the appData and emit save-changes
        //         this.$set(this.appData.transaction, 'uiDeclarationContact', decContact);
        //         this.$emit('save-changes');
        //     } catch (error) {
        //         this.$emit("handleError", error);
        //     }
        // },

        async createDecForm() {
            this.creatingDeclarationForm = true;
            try {
                const { data } = await this.$newBusinessDataService.createDeclarationForm();
                if (data.result) {
                    let decFormInfo = data.result;
                    this.appData.transaction.K_DeclarationFormStatus = decFormInfo.K_DeclarationFormStatus;
                    this.appData.transaction.K_DeclarationFormLink = decFormInfo.K_DeclarationFormLink;
                    this.appData.documents.declarationForm = {
                        id: decFormInfo.id,
                        view_url: decFormInfo.K_DeclarationFormLink,
                    };

                    this.$emit("updateWizardAppDataString");
                }
            } catch (error) {
                this.$emit("handleError", error);
            } finally {
                this.creatingDeclarationForm = false;
            }
        },

        async saveDeclarationContact() {
            const decContact = this.appData.transaction.uiDeclarationContact;
            if (!decContact) return;

            // Set the proper transaction fields that get saved to server
            this.$set(this.appData.transaction, 'K_DeclarationContact', decContact.Name);
            this.$set(this.appData.transaction, 'Email', decContact.Email);

            // Keep the UI contact object for display purposes
            this.$set(this.appData.transaction, 'uiDeclarationContact', decContact);

            this.$emit('save-changes');
        },

    },

    watch: {
        'appData.policy.K_PrimaryPolicyContact': {
            async handler(newVal) {
                this.contacts = await this.getStrataCompanyContacts();

                // If no declaration contact is selected, default to the primary policy contact
                if (!this.appData.transaction.uiDeclarationContact && newVal) {
                    this.appData.transaction.uiDeclarationContact = newVal;
                    await this.saveDeclarationContact();
                }
            }
        },
        'appData.strataCompany': {
            async handler() {
                this.contacts = await this.getStrataCompanyContacts();
            }
        }
    },
};
</script>
