<template>
    <div>
        <b-card border-variant="danger" body-class="px-4 py-4">
            <h4 class="font-weight-bold text-dark">
                <i class="fas fa-exclamation-circle text-danger fa-md"></i>
                <span class="ml-2" style="vertical-align: center">Alert</span>
            </h4>
            <div>
                <textarea type="text" class="form-control col-12 pt-5 pb-5 alert-textarea"
                    :class="{ 'expanded': showAll || isEditMode }" maxlength="32000" v-model="localAlertText"
                    :readonly="isReadOnly || !isEditMode" ref="textAreaRef" :style="textareaStyle" />
                <small v-if="isEditMode" class="form-text text-muted">
                    Max 32,000 characters.
                </small>
            </div>

            <div class="form-group mt-4 mb-0">
                <button v-if="!isEditMode" class="btn btn-primary btn-sm" @click.prevent="editAlert" :disabled="isReadOnly">
                    <i class="fas fa-edit"></i>
                    Edit
                </button>

                <button v-if="!isEditMode" class="btn btn-outline-secondary btn-sm ml-3" @click="toggleShowAll">
                    {{ showAll ? 'Show Less' : 'Show All' }}
                </button>

                <template v-if="isEditMode">
                    <button class="btn btn-primary btn-sm" @click.prevent="saveAlert" :disabled="savingData">
                        Save
                    </button>
                    <b-spinner class="ml-3 align-middle" variant="primary" v-if="savingData"></b-spinner>
                    <button class="btn btn-secondary btn-sm ml-3" @click.prevent="cancelEditMode" :disabled="savingData">
                        Cancel
                    </button>
                </template>
            </div>
        </b-card>
    </div>
</template>

<style scoped>
.alert-textarea {
    resize: none !important;
    transition: height 0.9s ease;
    height: 125px !important;
    overflow: auto;
}

.alert-textarea.expanded {
    height: var(--expanded-height) !important;
    overflow: hidden !important;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "PolicyAlert",
    props: {
        appData: Object,
        isReadOnly: Boolean,
    },

    data() {
        return {
            tempAlertText: '',
            isEditMode: false,
            savingData: false,
            showAll: false,
            expandedHeight: '100px'
        };
    },

    computed: {
        ...mapGetters('policyAlert', ['getAlertText']),

        localAlertText: {
            get() {
                return this.getAlertText;
            },
            set(value) {
                this.setAlertText(value);
            }
        },

        textareaStyle() {
            if (this.showAll || this.isEditMode) {
                return {
                    '--expanded-height': this.expandedHeight
                };
            }
            return {};
        }
    },

    mounted() {
        this.setAlertText(this.appData.asset.K_Alert);
        // Force initial collapsed state
        const textArea = this.$refs.textAreaRef;
        if (textArea) {
            textArea.style.height = '100px';
        }
    },

    methods: {
        ...mapActions('policyAlert', ['updateAlertText', 'setAlertText']),

        calculateExpandedHeight() {
            const textArea = this.$refs.textAreaRef;
            if (textArea) {
                // Store current height
                const currentHeight = textArea.style.height;
                // Remove height constraint
                textArea.style.height = 'auto';
                // Get the scrollHeight
                const scrollHeight = textArea.scrollHeight;
                // Set the expanded height
                this.expandedHeight = `${scrollHeight}px`;
                // Only reset height if not expanded and not in edit mode
                if (!this.showAll && !this.isEditMode) {
                    textArea.style.height = '100px';
                }
            }
        },

        toggleShowAll() {
            this.showAll = !this.showAll;
            if (this.showAll) {
                this.$nextTick(() => {
                    this.calculateExpandedHeight();
                });
            }
        },

        editAlert() {
            if (!this.isReadOnly) {
                this.isEditMode = true;
                this.tempAlertText = this.localAlertText;
                this.$nextTick(() => {
                    this.calculateExpandedHeight();
                });
            }
        },

        cancelEditMode() {
            this.setAlertText(this.tempAlertText);
            this.isEditMode = false;
            this.showAll = false;
        },

        saveAlert() {
            this.savingData = true;
            this.updateAlertText(this.localAlertText)
                .then(() => {
                    this.isEditMode = false;
                    this.savingData = false;
                    this.showAll = false;
                    this.$emit('alertSaved', this.localAlertText);
                })
                .catch((error) => {
                    this.savingData = false;
                    this.isEditMode = false;
                    this.$emit("handleError", error);
                });
        }
    },

    watch: {
        localAlertText() {
            if (this.isEditMode || this.showAll) {
                this.$nextTick(() => {
                    this.calculateExpandedHeight();
                });
            }
        },

        isReadOnly(newVal) {
            if (newVal) {
                this.isEditMode = false;
                this.showAll = false;
            }
        },

        isEditMode(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.calculateExpandedHeight();
                });
            }
        },
    }
};
</script>
