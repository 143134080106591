<template>
    <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
        <div>
            <div v-if="appData">
                <instructions :instructions-text="instructions" />

                <div class="mt-10">
                    <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
                        <h4 class="font-weight-bold text-dark">
                            <i class="fa-solid fa-filter fa-md text-primary"></i>
                            <span class="ml-2" style="vertical-align: center">Broker Company</span>
                        </h4>
                        <div class="col-3">
                            <ValidationProvider rules="required" name="K_BrokerCompany" v-slot="{ errors }">
                                <b-form-select class="form-control mt-2" v-model="appData.asset.K_BrokerCompany"
                                    :disabled="isReadOnly">
                                    <option :value="null">-- Select --</option>
                                    <option value="Collective Insurance Brokers">Collective Insurance Brokers</option>
                                    <option value="Body Corporate Brokers">Body Corporate Brokers</option>
                                </b-form-select>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </b-card>
                </div>

                <div class="mt-10">
                    <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
                        <h4 class="font-weight-bold text-dark">
                            <i class="fa-solid fa-wave-square fa-md text-primary"></i>
                            <span class="ml-2" style="vertical-align: center">Transaction Type</span>
                        </h4>
                        <ValidationProvider rules="required" name="TransactionType" v-slot="{ errors }">
                            <v-radio-group v-model="appData.transaction.K_Type" @change="transactionTypeChanged">
                                <!-- TODO: future implementation: true new business -->
                                <!-- <v-radio label="A brand new policy" value="New Business" class="switch-radio"
                                :disabled="isReadOnly"></v-radio> -->
                                <v-radio label="Take-Up (existing policy)" value="Take-Up" class="switch-radio"
                                    :disabled="isReadOnly"></v-radio>

                            </v-radio-group>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-card>
                </div>


                <policy-details :app-data="appData" :ref-data="refData" :is-read-only="isReadOnly" v-on="$listeners" />

                <building-details :app-data="appData" :ref-data="refData" :is-read-only="isReadOnly" v-on="$listeners" />

                <div class="d-flex justify-content-end mt-5">
                    <b-button class="btn btn-primary" :disabled="isReadOnly || isSaving" @click="saveChanges">
                        <b-spinner small v-if="isSaving" class="mr-1"></b-spinner>
                        <i class="fas fa-floppy-disk"></i> Save Changes
                    </b-button>
                </div>

                <div class="mt-10">
                    <h4>How do you wish to enter/obtain the building details?</h4>

                    <ValidationProvider name="declarationRequired" v-slot="{ errors }">
                        <v-radio-group v-model="declarationRequired" @change="handleDeclarationChange">
                            <v-radio label="I want to send a Declaration Form to the Strata Manager" value="yes"
                                class="switch-radio" :disabled="isReadOnly" />
                            <v-radio label="I will enter these manually" value="no" class="switch-radio"
                                :disabled="isReadOnly" />
                        </v-radio-group>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import Instructions from "../../../common/Instructions.vue";
import PolicyDetails from "./PolicyDetails.vue";
import BuildingDetails from "./BuildingDetails.vue";

export default {
    name: "NewPolicyPage",

    components: {
        Instructions,
        PolicyDetails,
        BuildingDetails,
    },
    data() {
        return {
            isSaving: false,
            selectedOption: "Take-Up",
        };
    },

    props: {
        appData: Object,
        refData: Object,
        isReadOnly: Boolean,
    },

    created() {
        // Initialize with 'No' to ensure manual option is selected by default
        if (!this.appData.transaction) {
            this.$set(this.appData, 'transaction', {});
        }
        if (!this.appData.transaction.K_DeclarationRequired) {
            this.$set(this.appData.transaction, 'K_DeclarationRequired', 'No');
        }
    },

    methods: {
        transactionTypeChanged: function () {
            if (this.appData.transaction.K_Type === "Take-Up") {
                this.appData.insurer = null;
                this.appData.policy.K_PolicyNumber = null;
            }
            this.$emit("updateWizardTitle");
        },

        async handleDeclarationChange(value) {
            // Update via the computed property
            this.declarationRequired = value;

            // Update status and save
            this.setPolicyEnteredStatus();
            await this.saveChanges();

            // Double-check value after save
            this.$nextTick(() => {
                const expectedValue = value === 'yes' ? 'Yes' : 'No';
                if (this.appData.transaction.K_DeclarationRequired !== expectedValue) {
                    this.$set(this.appData.transaction, 'K_DeclarationRequired', expectedValue);
                }
            });
        },

        setPolicyEnteredStatus: function () {
            // Create a copy of existing policy data
            const currentPolicy = { ...this.appData.policy };

            this.appData.statusFlags.policyDetailsEntered = true;

            // Ensure policy data is preserved
            this.$nextTick(() => {
                this.appData.policy = currentPolicy;
            });

            this.$emit("updateServerTransactionStatus", "policyDetailsEntered");
        },

        async handleStrataCompanyChange(value) {
            if (value) {
                // Ensure proper saving after strata company change
                await this.saveChanges();
            }
        },

        async saveChanges() {
            try {
                const isValid = await this.$refs.wizardPage1.validate();
                if (!isValid) return;

                this.isSaving = true;

                // No longer need to store policy data separately since
                // parent component will preserve it

                await this.$emit('save-changes');

            } catch (error) {
                this.$bvToast.toast('Failed to save changes. Please try again.', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                });
                console.error('Save failed:', error);
            } finally {
                this.isSaving = false;
            }
        }
    },

    computed: {
        instructions: function () {
            return "Please enter the general details relating to the policy and building below.";
        },

        declarationRequired: {
            get() {
                // Convert CRM Yes/No to UI yes/no
                return this.appData.transaction?.K_DeclarationRequired === 'Yes' ? 'yes' : 'no';
            },
            set(value) {
                // Ensure appData.transaction exists
                if (!this.appData.transaction) {
                    this.$set(this.appData, 'transaction', {});
                }
                // Convert UI yes/no to CRM Yes/No
                this.$set(this.appData.transaction, 'K_DeclarationRequired', value === 'yes' ? 'Yes' : 'No');
            }
        },
    },

    watch: {
        'appData.transaction.K_DeclarationRequired': {
            immediate: true,
            handler(newVal) {
                // Convert CRM Yes/No to UI yes/no
                if (newVal !== undefined) {
                    const uiValue = newVal === 'Yes' ? 'yes' : 'no';
                    this.$set(this.appData, 'declarationRequired', uiValue);
                }
            }
        }
    },
};
</script>
