<template> 
  <div v-if="comparisonData">
    <div class="row">
      <div class="col-12">
        <table
          class="table table-hover table-bordered table-vertical-center table-sm"
        >
          <thead> 
            <tr>
              <th></th>
              <th
                v-for="(item, index) in comparisonData.insurers"
                class="different-values"
                :key="index"
              >
                {{ item }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in comparisonData.dates"
              :key="item.apiKey"
              :class="{ 'different-values': areValuesDifferent(item.data) }"
            >
              <td>{{ item.displayName }}</td>
              <td v-for="(col, colIndex) in item.data" :key="colIndex">
                <label v-if="item.type === 'date' && col" class="col-form-label"
                  >{{
                    col | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY")
                  }}
                </label>
                <label v-else-if="col" class="col-form-label">{{ col }} </label>
              </td>
            </tr>
            <tr>
              <td style="height: 50px" :colspan="colspan"></td>
            </tr>
            <tr
              v-for="item in comparisonData.sumInsuredData"
              :key="item.apiKey"
              :class="{ 'different-values': areValuesDifferent(item.data) }"
            >
              <td>{{ item.displayName }}</td>
              <td v-for="(col, colIndex) in item.data" :key="colIndex">
                <label
                  v-if="item.type === 'currency' && col"
                  class="col-form-label"
                  >{{ col | currency("$", ",", 0, ".", "front", true) }}
                </label>
                <label
                  v-else-if="item.type === 'date' && col"
                  class="col-form-label"
                  >{{
                    col | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY")
                  }}
                </label>
                <label v-else-if="col" class="col-form-label">{{ col }} </label>
              </td>
            </tr>
            <tr>
              <td style="height: 50px" :colspan="colspan"></td>
            </tr>
            <tr
              v-for="item in comparisonData.excessData"
              :key="item.apiKey"
              :class="{ 'different-values': areValuesDifferent(item.data) }"
            >
              <td>{{ item.displayName }}</td>
              <td v-for="(col, colIndex) in item.data" :key="colIndex">
                <label
                  v-if="item.type === 'currency' && col"
                  class="col-form-label"
                  >{{ col | currency("$", ",", 0, ".", "front", true) }}
                </label>
                <label
                  v-else-if="item.type === 'date' && col"
                  class="col-form-label"
                  >{{
                    col | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY")
                  }}
                </label>
                <label v-else-if="col" class="col-form-label">{{ col }} </label>
              </td>
            </tr>
            <tr>
              <td style="height: 50px" :colspan="colspan"></td>
            </tr>
            <tr
              v-for="item in comparisonData.financialData"
              :key="item.apiKey"
              :class="{ 'different-values': areValuesDifferent(item.data) }"
            >
              <td :class="{ 'bold-label': isBoldLabel(item.displayName) }">
                {{ item.displayName }}
              </td>
              <td v-for="(col, colIndex) in item.data" :key="colIndex">
                <label
                  v-if="item.type === 'currency' && col"
                  class="col-form-label"
                  >{{ col | currency("$", ",", 2, ".", "front", true) }}
                </label>
                <label
                  v-else-if="item.type === 'date' && col"
                  class="col-form-label"
                  >{{
                    col | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY")
                  }}
                </label>
                <label v-else-if="col" class="col-form-label">{{ col }} </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "ComparisonTable",

  props: {
    comparisonData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {};
  },

  /** Every time comparisonData reloads and internal historicalData changes, recalculate the % */
  watch: {
    historicalData: function (newHistoricalData /*oldHistoricalData*/) {
      this.recalculateHistoricalDataPercent(newHistoricalData);
    },
  },

  mounted() {
    this.recalculateHistoricalDataPercent(this.historicalData);
  },

  computed: {
    colspan: function () {
      if (this.comparisonData && Array.isArray(this.comparisonData.insurers)) {
        return this.comparisonData.insurers.length + 1;
      }
      return 1;
    },

    historicalData: function () {
      return this.comparisonData.historicalData;
    },
  },

  methods: {
    areValuesDifferent: function (data) {
      if (Array.isArray(data) && data.length > 0) {
        return _.some(data, (val) => {
          return val !== data[0];
        });
      }
      return false;
    },

    isBoldLabel: function (label) {
      return (
        label === "Total Insurer Payable" || label === "Total Premium Payable"
      );
    },

    valueChanged: function (historyItem) {
      let priorYearValue = historyItem.data[0];
      let currentYearValue = historyItem.data[1];

      let percentChange = this.calculatePercent(
        currentYearValue,
        priorYearValue
      );
      if (!_.isNil(percentChange)) {
        percentChange = this.round(percentChange - 100);
      }
      if (historyItem.data.length === 2) {
        historyItem.data.push(0);
      }
      this.$set(historyItem.data, 2, percentChange);
    },

    recalculateHistoricalDataPercent: function (historicalData) {
      if (Array.isArray(historicalData)) {
        for (let hData of historicalData) {
          this.valueChanged(hData);
        }
      }
    },

    calculatePercent: function (value, parentValue) {
      if (!_.isNil(value) && !_.isNil(parentValue)) {
        return this.round((Number(value) * 100) / Number(parentValue));
      }
      return null;
    },

    calculatePercentAmount: function (currencyValue, percent) {
      if (!_.isNil(currencyValue) && !_.isNil(percent)) {
        return this.round((Number(currencyValue) * Number(percent)) / 100);
      }
      return null;
    },

    round: function (number) {
      if (!_.isNil(number)) {
        return Math.round(Number(number) * 100 + Number.EPSILON) / 100;
      }
      return null;
    },
  },
};
</script>

<style>
.different-values {
  background-color: #ffebcc;
}

.bold-label {
  font-weight: bold;
}

.custom-table {
  table-layout: fixed; /* This helps with precise column width control */
  width: 100%; /* Full width of the container */
}

.custom-table .first-column {
  width: auto; /* Adjusts automatically to take up remaining space */
}

.custom-table .last-column {
  max-width: 100px; /* Example max-width, adjust as needed */
}


</style>
