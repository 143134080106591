<template>
    <ValidationObserver ref="wizardPage3" id="wizardPage3" name="wizardPage3">
        <div>
            <div v-if="appData">
                <instructions :instructions-text="instructions" />

                <policy-alert class="mt-10" :app-data="appData" :is-read-only="isReadOnly"
                    @alertSaved="$emit('alertSaved', $event)" @handleError="$emit('handleError', $event)" />

                <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
                    <h4 class="font-weight-bold text-dark">
                        <i class="fas fa-file-alt fa-md text-primary"></i>
                        <span class="ml-2" style="vertical-align: center">Building and Insurance Details</span>
                    </h4>

                    <div class="text-center" v-if="appData.declarationRequired === 'yes'">
                        <label class="col-form-label font-weight-bold font-size-lg" v-html="declarationStatus"></label>
                    </div>

                    <div v-if="appData.declarationRequired === 'yes' && !declarationFinalised
                        ">
                        <div class="text-center">
                            <button class="btn btn-outline-danger" @click.prevent="openOverrideConfirmationDialog">
                                Broker Override
                            </button>
                        </div>
                        <div class="text-center">
                            <!-- Hidden input to validate if the declaration has been finalised -->
                            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                name="declarationFinalised" v-slot="{ errors }">
                                <input type="hidden" v-model="declarationFinalised" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <br />
                    </div>

                    <!-- <div v-if="appData.declarationRequired === 'no' || declarationFinalised">
                        <div v-if="appData.declarationRequired === 'yes' && !declarationOverridden
                            "> -->
                    <div>
                        <div>
                            <div class="text-center" v-if="appData.transaction.K_DeclarationFormLink">
                                <a :href="declarationFormLink" class="btn btn-primary" target="_blank">
                                    <i class="fa-regular fa-clipboard fa-md mr-2"></i>
                                    Review Declaration Form
                                </a>
                            </div>
                            <br />
                        </div>

                        <declaration-form v-if="displayData && displayData.sumInsuredData"
                            :formData="displayData.sumInsuredData" :configuration="sumsDataTableConfiguration"
                            :is-read-only="isReadOnly" @delete-item="deleteItem" @input="handleSumInsuredChange" />

                        <declaration-form :formData="displayData.excessData" :configuration="excessDataTableConfiguration"
                            :is-read-only="isReadOnly" @delete-item="deleteItem" @input="handleExcessChange" />

                        <building-properties :form-data="displayData.assetPropertiesData"
                            :configuration="buildingPropertiesConfiguration" :is-read-only="isReadOnly"
                            @delete-item="deleteItem" @input="handleBuildingPropertiesChange" />
                    </div>

                    <div class="d-flex justify-content-start mt-5">
                        <b-button class="btn btn-primary" :disabled="isReadOnly || isSaving" @click="saveChanges">
                            <b-spinner small v-if="isSaving" class="mr-1"></b-spinner>
                            <i class="fas fa-floppy-disk"></i> Save Changes
                        </b-button>
                    </div>
                </b-card>
            </div>

            <claims-history :claims="displayData.claims || []" :is-read-only="isReadOnly"
                @claims-updated="handleClaimsUpdate" @save-claims="saveClaims" />


            <div>
                <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
                    <h4 class="font-weight-bold text-dark">
                        <i class="fas fa-circle-plus fa-md text-primary"></i>
                        <span class="ml-2" style="vertical-align: center">Endorsements / Conditions</span>
                    </h4>
                    <h6 class="font-weight-bold text-dark mt-6">
                        <textarea class="form-control mt-3 min-rows" v-model="appData.policy.K_EndorsementsConditions"
                            :disabled="isReadOnly" style="width: 100%;" placeholder="If applicable"
                            maxlength="32000"></textarea>
                        <small class="form-text text-muted">Max 32,000 characters</small>
                    </h6>
                    <div class="d-flex justify-content-start mt-5">
                        <b-button class="btn btn-primary" :disabled="isReadOnly || isSaving" @click.prevent="saveChanges"
                            v-if="showSaveButton">
                            <b-spinner small v-if="isSaving" class="mr-1"></b-spinner>
                            <i class="fas fa-floppy-disk"></i> Save Changes
                        </b-button>
                    </div>
                </b-card>
            </div>


            <!-- <div v-if="declarationFinalised">
                <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                    name="appData.statusFlags.declarationDetailsConfirmed" v-slot="{ errors }">
                    <div class="row mt-10">
                        <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                            <label>
                                <input type="checkbox" :disabled="isReadOnly"
                                    v-model="appData.statusFlags.declarationDetailsConfirmed" @change="
                                        $emit(
                                            'updateServerTransactionStatus',
                                            'declarationDetailsConfirmed'
                                        )
                                        " />
                                <span></span>
                            </label>
                        </span>
                        <label class="col-form-label font-size-h5 ml-2 col-10">
                            I confirm that I have reviewed the declaration details.
                        </label>
                    </div>
                    <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
            </div> -->

            <b-modal v-if="!isReadOnly" ref="overrideConfirmationDialog" title="Confirm Broker Override" size="md"
                no-close-on-esc no-close-on-backdrop hide-header-close @ok="overrideDeclaration"
                @cancel="clearOverrideReason">
                Please enter the reason for overriding the Declaration Form.<span class="text-danger">*</span>
                <ValidationProvider rules="required|max:2000" name="appData.brokerOverrideReason" v-slot="{ errors }">
                    <div class="form-group mt-5">
                        <b-textarea class="form-control min-rows" v-model="overrideReason"></b-textarea>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </b-modal>
        </div>
    </ValidationObserver>
</template>

<style>
.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import Instructions from "../../../common/Instructions";
import DeclarationForm from "../../../common/DeclarationForm.vue";
import BuildingProperties from "../../../common/BuildingProperties.vue";
import PolicyAlert from "../../../common/PolicyAlert";
import ClaimsHistory from "./ClaimsHistory.vue";

export default {
    name: "ReviewPage",

    components: {
        Instructions,
        DeclarationForm,
        BuildingProperties,
        ClaimsHistory,
        PolicyAlert
    },

    props: {
        appData: Object,
        isReadOnly: Boolean,
        claims: []
    },

    computed: {
        instructions: function () {
            return "Please review and confirm the sums insured, excesses, disclosure information & claims history below.";
        },

        showSaveButton() {
            return !this.isReadOnly;
        },

        displayData() {
            // Use declaration form data if available, fall back to app data
            const declarationData = this.declarationFormData?.data || this.appData?.declarationData || {};
            return {
                sumInsuredData: declarationData.sumInsuredData || [],
                excessData: declarationData.excessData || [],
                assetPropertiesData: declarationData.assetPropertiesData || [],
                claims: this.appData?.claims || []
            };
        },

        hasDeclarationFormData() {
            return !!this.appData?.transaction?.K_DeclarationFormDataAttachmentId || !!this.appData?.declarationData?.sumInsuredData?.length;
        },

        sumsDataTableConfiguration: function () {
            return {
                title: "Sums Insured",
                renewalTitle: "Value",
                showNewValue: true,
                showCurrentValue: false,
                allowDeletion: true,
                allowAddition: true,
                addButtonTitle: "",
            };
        },

        excessDataTableConfiguration: function () {
            return {
                title: "Excesses",
                renewalTitle: "Value",
                showNewValue: true,
                showCurrentValue: false,
                allowDeletion: true,
                allowAddition: true,
                addButtonTitle: "",
            };
        },

        buildingPropertiesConfiguration: function () {
            return {
                title: "Building Properties",
                newValueTitle: "Value",
                showNewValue: true,
                showCurrentValue: false,
                allowDeletion: true,
                allowAddition: true,
                addButtonTitle: "",
            };
        },

        declarationFinalised: function () {
            let decStatus = _.get(
                this.appData,
                "transaction.K_DeclarationFormStatus"
            );
            return decStatus === "Submitted" || decStatus === "Overridden";
        },

        declarationOverridden: function () {
            let decStatus = _.get(
                this.appData,
                "transaction.K_DeclarationFormStatus"
            );
            return decStatus === "Overridden";
        },

        declarationFormLink: function () {
            let decFormLink = _.get(
                this.appData,
                "transaction.K_DeclarationFormLink"
            );
            if (!decFormLink) {
                decFormLink = _.get(this.appData, "documents.declarationForm.view_url");
            }
            return decFormLink;
        },

        declarationStatus: function () {
            let decReceivedTimestamp = _.get(
                this.appData,
                "transaction.K_DeclarationFormSubmissionOverrideTime"
            );
            if (decReceivedTimestamp) {
                decReceivedTimestamp = moment
                    .tz(decReceivedTimestamp, "Australia/Sydney")
                    .format("DD/MM/YYYY hh:mm:ss A");
            }

            let decStatus = `Awaiting Declaration Form from the Strata contact.`;
            if (decReceivedTimestamp) {
                if (this.declarationOverridden) {
                    let overridingBroker =
                        _.get(this.appData, "transaction.K_OverridingBroker") || "Broker";
                    decStatus = `<span class="text-danger">Declaration Form was overridden by ${overridingBroker} on ${decReceivedTimestamp}.</span>`;
                    let reason = _.get(this.appData, "transaction.K_OverrideReason");
                    if (reason) {
                        decStatus = `${decStatus}<br/>Reason: ${reason}`;
                    }
                } else {
                    let decFormSubmittedBy =
                        _.get(this.appData, "transaction.K_DeclarationFormSubmittedBy") ||
                        "Strata Contact";
                    decStatus = `<span class="text-primary">Declaration Form was submitted by ${decFormSubmittedBy} on ${decReceivedTimestamp}.</span>`;
                }
            }
            return decStatus;
        },

    },

    data() {
        return {
            overrideReason: null,
            declarationFormData: null,
            isSaving: false,
            hasUnsavedChanges: false
        };
    },

    async mounted() {
        // Check if we need to load declaration data when component mounts
        if (!this.hasDeclarationFormData) {
            await this.loadDeclarationFormData();
        }
    },

    methods: {
        trackChanges() {
            this.hasUnsavedChanges = true;
        },

        async saveChanges() {
            if (!this.hasUnsavedChanges) {
                console.log('No changes to save');
                return;
            }

            console.log('Save Changes clicked');
            try {
                this.isSaving = true;
                console.log('Starting save process...');

                // Store current policy data
                const currentPolicy = { ...this.appData.policy };

                // Create payload with all the data that needs to be saved
                const savePayload = {
                    policy: currentPolicy,
                    declarationData: this.declarationFormData?.data,
                    sumInsuredData: this.displayData.sumInsuredData,
                    excessData: this.displayData.excessData,
                    assetPropertiesData: this.displayData.assetPropertiesData
                };

                // Save all changes
                await Promise.all([
                    // Save declaration form data
                    this.declarationFormData && this.$newBusinessDataService.saveDeclarationFormData({
                        data: {
                            ...this.declarationFormData.data,
                            K_DeclarationFormStatus: this.appData.transaction.K_DeclarationFormStatus
                        }
                    }),

                    // Save wizard app data
                    this.$newBusinessDataService.saveWizardAppData({
                        ...this.appData,
                        declarationData: {
                            sumInsuredData: this.displayData.sumInsuredData,
                            excessData: this.displayData.excessData,
                            assetPropertiesData: this.displayData.assetPropertiesData
                        }
                    })
                ]);

                // Emit that changes were saved
                this.$emit('save-changes');
                this.$emit('updateWizardAppDataString');

                // Reset unsaved changes flag
                this.hasUnsavedChanges = false;

            } catch (error) {
                console.error('Save failed:', error);
                this.$bvToast.toast('Failed to save changes. Please try again.', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                });
                this.$emit('handleError', error);
            } finally {
                this.isSaving = false;
            }
        },

        handleSumInsuredChange(updatedData) {
            if (this.declarationFormData) {
                this.declarationFormData.data.sumInsuredData = updatedData;
            }
            this.handleDeclarationDataChange();
        },

        handleExcessChange(updatedData) {
            if (this.declarationFormData) {
                this.declarationFormData.data.excessData = updatedData;
            }
            this.handleDeclarationDataChange();
        },

        handleBuildingPropertiesChange(updatedData) {
            if (this.declarationFormData) {
                this.declarationFormData.data.assetPropertiesData = updatedData;
            }
            this.handleDeclarationDataChange();
        },

        async handleDeclarationDataChange() {
            try {
                // Update local app data
                this.$emit('update:appData', {
                    ...this.appData,
                    declarationData: {
                        sumInsuredData: this.declarationFormData?.data?.sumInsuredData || [],
                        excessData: this.declarationFormData?.data?.excessData || [],
                        assetPropertiesData: this.declarationFormData?.data?.assetPropertiesData || []
                    }
                });

                // Save declaration data to server
                await this.$newBusinessDataService.saveDeclarationFormData({
                    data: {
                        ...this.declarationFormData.data,
                        K_DeclarationFormStatus: this.appData.transaction.K_DeclarationFormStatus
                    }
                });

                // Save wizard app data
                await this.$newBusinessDataService.saveWizardAppData(this.appData);

                // Emit update event to refresh data
                this.$emit('updateWizardAppDataString');

            } catch (error) {
                console.error('Error saving declaration data:', error);
                this.$emit('handleError', error);
            }
        },

        openOverrideConfirmationDialog: function () {
            this.$refs["overrideConfirmationDialog"].show();
        },

        handleClaimsUpdate(updatedClaims) {
            this.$emit('update:appData', {
                ...this.appData,
                claims: updatedClaims
            });
        },

        async saveClaims(claimsToSave) {
            try {
                const updatedAppData = {
                    ...this.appData,
                    claims: claimsToSave
                };

                await this.$newBusinessDataService.saveWizardAppData(updatedAppData);

            } catch (error) {
                console.error('Error saving claims:', error);
            }
        },

        overrideDeclaration: function (bvModalEvt) {
            if (!this.overrideReason) {
                bvModalEvt.preventDefault();
                return;
            }
            this.$emit("overrideDeclaration", this.overrideReason);
        },

        clearOverrideReason: function () {
            this.overrideReason = null;
        },

        deleteItem(item, formData) {
            let index = formData.findIndex(dataItem => dataItem === item);
            if (index !== -1) {
                formData.splice(index, 1);
                // Trigger declaration data change handler after deletion
                this.handleDeclarationDataChange();
            }
        },


        async loadDeclarationFormData() {
            try {
                const response = await this.$newBusinessDataService.getDeclarationFormData();

                this.declarationFormData = response?.data?.result;

                // If we got declaration data, update the appData declaration data
                if (this.declarationFormData) {
                    this.$emit('update:appData', {
                        ...this.appData,
                        declarationData: {
                            sumInsuredData: this.declarationFormData.sumInsuredData || [],
                            excessData: this.declarationFormData.excessData || [],
                            assetPropertiesData: this.declarationFormData.assetPropertiesData || []
                        }
                    });
                }
            } catch (error) {
                console.error('Error loading declaration form data:', error);
                // Handle error - maybe show a notification
            }
        },
    },

    watch: {
        // Watch for changes in appData to check if we need to load declaration data
        'appData.transaction.K_DeclarationFormDataAttachmentId': {
            immediate: true,
            async handler(newVal) {
                if (newVal && !this.declarationFormData) {
                    await this.loadDeclarationFormData();
                }
            }
        },

        'displayData.sumInsuredData': {
            deep: true,
            handler() {
                this.trackChanges();
            }
        },
        'displayData.excessData': {
            deep: true,
            handler() {
                this.trackChanges();
            }
        },
        'displayData.assetPropertiesData': {
            deep: true,
            handler() {
                this.trackChanges();
            }
        },
        'appData.policy': {
            deep: true,
            handler() {
                this.trackChanges();
            }
        }
    },
};
</script>
