<template>
  <ValidationObserver ref="wizardPage4" id="wizardPage4" name="wizardPage4">
    <div>
      <div v-if="appData && refData">
        <instructions :instructions-text="instructions" />

        <ValidationProvider
          rules="max:2000"
          name="appData.transaction.K_SpecialInstructionsForInsurer"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label class="col-form-label font-weight-bold font-size-lg">
              Special Instructions for Insurers (to be included in the Quote
              Slip)
            </label>
            <b-textarea
              class="form-control min-rows"
              v-model="appData.transaction.K_SpecialInstructionsForInsurer"
              :disabled="isReadOnly || quoteSlipExists || isOverLimit"
              trim
              maxlength="2000"
            ></b-textarea>
            <small class="form-text text-muted">Maximum 2,000 characters</small>
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <div class="form-group" v-if="!quoteSlipExists">
          <button
            class="btn btn-primary"
            :disabled="isReadOnly || creatingQuoteSlip"
            @click.prevent="createQuoteSlip"
          >
            Create Quote Slip
          </button>
          <b-spinner
            v-if="creatingQuoteSlip"
            class="ml-3 align-middle"
            variant="primary"
            label="Please wait..."
          ></b-spinner>
        </div>
        <div class="form-group" v-if="quoteSlipExists">
          <button
            class="btn btn-sm btn-outline-danger"
            :disabled="isReadOnly"
            @click.prevent="openQuoteSlipResetConfirmation"
          >
            Unlock Above Fields
          </button>
        </div>

        <Document
          v-if="quoteSlipExists"
          class="mt-10"
          :document-name="'Quote Slip'"
          :document-data="appData.documents.quoteSlip"
          :on-create-document="createQuoteSlip"
          :processing-indicator="creatingQuoteSlip"
          :download-enabled="false"
          :is-read-only="isReadOnly"
        >
        </Document>

        <!-- Hidden input to validate if the user created the quote slip -->
        <ValidationProvider
          :rules="{ 'required-action': { allowFalse: false } }"
          name="appData.documents.quoteSlip.id"
          v-slot="{ errors }"
        >
          <input type="hidden" v-model="appData.documents.quoteSlip.id" />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <div v-if="quoteSlipExists">
          <b-card border-variant="primary" body-class="px-4 py-4" class="mt-15">
            <h4 class="font-weight-bold text-dark">
              <span class="ml-2" style="vertical-align: center"
                >Select Insurers</span
              >
            </h4>

            <div class="row mt-3">
              <div class="col-5">
                <h5 class="ml-2 mt-3">Panel A Insurers</h5>
                <table
                  class="table table-borderless"
                  style="display: block; height: 300px; overflow-y: auto"
                >
                  <tr v-for="item in panelAInsurers" :key="item.id">
                    <td width="1%" style="vertical-align: middle">
                      <b-form-checkbox
                        size="lg"
                        v-model="item.selected"
                        :disabled="
                          isReadOnly ||
                          isCurrentInsurer(item) ||
                          insurerSelectionConfirmed
                        "
                      >
                      </b-form-checkbox>
                    </td>
                    <td style="vertical-align: middle">
                      {{ item.Name }}
                      <b-badge
                        v-if="isCurrentInsurer(item)"
                        variant="light"
                        class="ml-1"
                      >
                        Current
                      </b-badge>
                    </td>
                    <td width="50%">
                      <b-form-select
                        v-model="item.selectedContact"
                        :disabled="
                          isReadOnly ||
                          !item.selected ||
                          insurerSelectionConfirmed
                        "
                      >
                        <b-form-select-option :value="null"
                          >Select a Contact</b-form-select-option
                        >
                        <b-form-select-option
                          v-for="contact in item.contacts"
                          :key="contact.id"
                          :value="contact"
                          >{{ contact.Name }} - {{ contact.Email }}
                        </b-form-select-option>
                      </b-form-select>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="col-1"></div>
              <div class="col-5">
                <h5 class="ml-2 mt-3">Panel B Insurers</h5>
                <table
                  class="table table-borderless"
                  style="display: block; height: 300px; overflow-y: auto"
                >
                  <tr v-for="item in panelBInsurers" :key="item.id">
                    <td width="1%" style="vertical-align: middle">
                      <b-form-checkbox
                        size="lg"
                        v-model="item.selected"
                        :disabled="
                          isReadOnly ||
                          isCurrentInsurer(item) ||
                          insurerSelectionConfirmed
                        "
                      >
                      </b-form-checkbox>
                    </td>
                    <td style="vertical-align: middle">
                      {{ item.Name }}
                      <b-badge
                        v-if="isCurrentInsurer(item)"
                        variant="light"
                        class="ml-1"
                      >
                        Current
                      </b-badge>
                    </td>
                    <td width="50%">
                      <b-form-select
                        v-model="item.selectedContact"
                        :disabled="
                          isReadOnly ||
                          !item.selected ||
                          insurerSelectionConfirmed
                        "
                      >
                        <b-form-select-option :value="null"
                          >Select a Contact</b-form-select-option
                        >
                        <b-form-select-option
                          v-for="contact in item.contacts"
                          :key="contact.id"
                          :value="contact"
                          >{{ contact.Name }} - {{ contact.Email }}
                        </b-form-select-option>
                      </b-form-select>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card>

          <div class="text-danger mt-5" v-if="insurerSelectionError">
            {{ insurerSelectionError }}
          </div>
          <div class="form-group mt-5" v-if="!insurerSelectionConfirmed">
            <button
              class="btn btn-primary"
              @click.prevent="finaliseInsurers"
              :disabled="savingData"
            >
              Confirm Insurers Selection
            </button>
            <b-spinner
              v-if="savingData"
              class="ml-3 align-middle"
              variant="primary"
              label="Please wait..."
            ></b-spinner>
          </div>
          <div class="form-group mt-5" v-if="insurerSelectionConfirmed">
            <button
              class="btn btn-sm btn-outline-danger"
              :disabled="isReadOnly"
              @click.prevent="openInsurerSelectionResetConfirmation"
            >
              Unlock Insurer Selection
            </button>
          </div>

          <!-- Hidden input to validate if the insurer selection is confirmed -->
          <ValidationProvider
            :rules="{ 'required-action': { allowFalse: false } }"
            name="insurerSelectionConfirmed"
            v-slot="{ errors }"
          >
            <input type="hidden" v-model="insurerSelectionConfirmed" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>

          <div v-if="insurerSelectionConfirmed" class="mt-10">
            <div class="form-group row">
              <button
                class="btn btn-primary ml-5"
                @click.prevent="
                  launchMailToLink(newBusinessQuoteSlipMailToLink)
                "
                :disabled="isReadOnly || appData.statusFlags.quoteSlipSent"
              >
                <i class="fa fa-envelope fa-md"></i>
                Send New Business Quote Slip
              </button>
            </div>

            <ValidationProvider
              :rules="{ 'required-action': { allowFalse: false } }"
              name="appData.statusFlags.quoteSlipSent"
              v-slot="{ errors }"
            >
              <div class="row mt-10">
                <span
                  class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
                >
                  <label>
                    <input
                      type="checkbox"
                      :disabled="isReadOnly"
                      v-model="appData.statusFlags.quoteSlipSent"
                      @change="
                        $emit('updateServerTransactionStatus', 'quoteSlipSent')
                      "
                    />
                    <span></span>
                  </label>
                </span>
                <label class="col-form-label font-size-h5 ml-2">
                  I confirm that I have sent the Quote Slip to the selected
                  insurers.
                </label>
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <b-modal
        ref="quoteSlipResetConfirmationDialog"
        title="Confirm"
        size="md"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        @ok="resetQuoteSlip"
      >
        This action will invalidate the previously generated Quote Slip, and you
        will need to generate a new Quote Slip and send it to the insurers.<br /><br />
        Are you sure you want to continue?
      </b-modal>

      <b-modal
        ref="insurerSelectionResetConfirmationDialog"
        title="Confirm"
        size="md"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        @ok="resetInsurerSelection"
      >
        This action will reset insurer selection, and you may need to send the
        Quote Slip to the newly selected insurers.<br /><br />
        Are you sure you want to continue?
      </b-modal>
    </div>
  </ValidationObserver>
</template>

<style>
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px); 
}
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import Instructions from "../../../common/Instructions";
import Document from "../../../common/Document";

export default {
  name: "InsurersPage",

  components: { Instructions, Document },

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      panelAInsurers: null,
      panelBInsurers: null,
      selectedInsurers: null,
      insurerSelectionError: null,
      insurerSelectionConfirmed: false,
      creatingQuoteSlip: false,
      savingData: false,
    };
  },
  mounted() {
    this.insurerSelectionConfirmed = this.appData.statusFlags.quoteSlipSent;

    let panelAInsurers = _.filter(this.refData.allInsurers, {
      K_Panel: "Panel A",
    });
    if (!Array.isArray(panelAInsurers)) {
      panelAInsurers = [];
    }
    let panelBInsurers = _.filter(this.refData.allInsurers, {
      K_Panel: "Panel B",
    });
    if (!Array.isArray(panelBInsurers)) {
      panelBInsurers = [];
    }

    this.panelAInsurers = _.cloneDeep(panelAInsurers);
    this.panelBInsurers = _.cloneDeep(panelBInsurers);

    this.panelAInsurers.forEach((ins) => {
      this.$set(ins, "selected", false);
      this.$set(ins, "selectedContact", null);
    });
    this.panelBInsurers.forEach((ins) => {
      this.$set(ins, "selected", false);
      this.$set(ins, "selectedContact", null);
    });

    if (this.appData && Array.isArray(this.appData.selectedInsurers)) {
      this.appData.selectedInsurers.forEach((selectedInsurer) => {
        // Find selectedInsurer in panels
        let insInPanel = _.find(this.panelAInsurers, {
          id: selectedInsurer.id,
        });
        if (!insInPanel || !insInPanel.id) {
          insInPanel = _.find(this.panelBInsurers, { id: selectedInsurer.id });
        }

        if (insInPanel && insInPanel.id) {
          insInPanel.selected = true;
          insInPanel.selectedContact = selectedInsurer.selectedContact;
        }
      });
    }
  },

  methods: {
    openQuoteSlipResetConfirmation: function () {
      this.$refs["quoteSlipResetConfirmationDialog"].show();
    },

    resetQuoteSlip: function () {
      this.appData.transaction.K_QuoteSlipLink = null;
      this.appData.documents.quoteSlip = {};
      this.appData.statusFlags.quoteSlipSent = false;
      this.insurerSelectionConfirmed = false;
    },

    createQuoteSlip: function () {
      let insurerInstructions =
        this.appData.transaction.K_SpecialInstructionsForInsurer;

      this.creatingQuoteSlip = true;
      this.$newBusinessDataService
        .createQuoteSlip(insurerInstructions)
        .then(({ data }) => {
          if (data.result) {
            let quoteSlipInfo = data.result;
            this.appData.documents.quoteSlip = {
              id: quoteSlipInfo.id,
              view_url: quoteSlipInfo.K_QuoteSlipLink,
            };
            this.$emit("updateWizardAppDataString");
          }
          this.creatingQuoteSlip = false;
        })
        .catch((error) => {
          this.creatingQuoteSlip = false;
          this.$emit("handleError", error);
        });
    },

    isCurrentInsurer: function (insurer) {
      let policyInsurerId = _.get(this.appData, "policy.K_Insurer.id");
      return policyInsurerId === insurer.id;
    },

    finaliseInsurers: function () {
      this.insurerSelectionError = null;

      let selectedInsurers = _.filter(this.panelAInsurers, { selected: true });
      if (!Array.isArray(selectedInsurers)) {
        selectedInsurers = [];
      }
      selectedInsurers = selectedInsurers.concat(
        _.filter(this.panelBInsurers, { selected: true })
      );

      // Confirm that insurer-contact has been selected
      let invalidInsurer = _.some(selectedInsurers, (ins) => {
        return !ins.selectedContact || !ins.selectedContact.id;
      });
      if (invalidInsurer) {
        this.insurerSelectionError = `Please select the contacts for all selected insurers.`;
        return;
      }

      this.appData.selectedInsurers = [];
      selectedInsurers.forEach((selectedInsurer) => {
        this.appData.selectedInsurers.push({
          id: selectedInsurer.id,
          Name: selectedInsurer.Name,
          K_Panel: selectedInsurer.K_Panel,
          selectedContact: selectedInsurer.selectedContact,
        });
      });

      // Save selected insurers to server
      this.savingData = true;
      this.$newBusinessDataService
        .saveSelectedInsurers(this.appData.selectedInsurers)
        .then(({ data }) => {
          if (data.result) {
            this.appData.quotes = data.result;
            this.$emit("updateWizardAppDataString");

            this.savingData = false;
            this.insurerSelectionConfirmed = true;
          }
        })
        .catch((error) => {
          this.$emit("handleError", error);
          this.savingData = false;
        });
    },

    openInsurerSelectionResetConfirmation: function () {
      this.$refs["insurerSelectionResetConfirmationDialog"].show();
    },

    resetInsurerSelection: function () {
      this.insurerSelectionConfirmed = false;
      this.appData.statusFlags.quoteSlipSent = false;
    },

    launchMailToLink: function (link) {
      if (link) {
        window.open(link);
      }
    },
  },

  computed: {
    instructions: function () {
      return "Please specify any special instructions for the insurer to consider when providing a quote, if any. Then create the Quote Slip PDF document by clicking the button below. A copy of the Quote Slip will be stored in the Documents folder in Box.";
    },

    isOverLimit() {
      if (this.appData && this.appData.transaction && this.appData.transaction.K_SpecialInstructionsForInsurer !== null) {
        return this.appData.transaction.K_SpecialInstructionsForInsurer.length > 2000;
      }
      return false;
    },

    quoteSlipExists: function () {
      let quoteSlipLink = _.get(this.appData, "documents.quoteSlip.view_url");
      return !!quoteSlipLink;
    },

    newBusinessQuoteSlipMailToLink: function () {
      let policyInsurerId = _.get(this.appData, "policy.K_Insurer.id");
      let nbInsurers = _.filter(this.appData.selectedInsurers, (ins) => {
        return ins.id !== policyInsurerId;
      });
      let email = "";
      if (Array.isArray(nbInsurers)) {
        nbInsurers.forEach((ins) => {
          let insEmail = ins.selectedContact ? ins.selectedContact.Email : null;
          if (insEmail) {
            email = `${email}${insEmail};`;
          }
        });
      }

      // TODO Confirm
      let planNumber = _.get(this.appData, "asset.K_PlanNumber") || "";
      let subject = `New Business Quotation Request - ${planNumber}`;
      subject = encodeURIComponent(subject);

      let address = _.get(this.appData, "asset.K_Address") || "";
      let renewalDateStr = _.get(this.appData, "transaction.K_FromDate");
      let quoteNeededBeforeDate = moment(renewalDateStr, "YYYY-MM-DD")
        .subtract(42, "days")
        .format("DD/MM/YYYY");
      let quoteSlipLink = _.get(this.appData, "documents.quoteSlip.view_url");

      // Add email body
      let emailBody =
        `Dear Insurer, %0D%0A %0D%0A` +
        `Please provide a GROSS quotation for ${planNumber} (${address}). %0D%0A` +
        `Please review our quotation submission, including 5 years claims history and any relevant building reports. %0D%0A %0D%0A` +
        `To access the quote slip online, please click on the link below.%0D%0A %0D%0A` +
        `${quoteSlipLink} %0D%0A%0D%0A` +
        `If you have any queries relating to our Quotation request, please do not hesitate to contact our office. ` +
        `We require your quotation as of close of business ${quoteNeededBeforeDate}. %0D%0A %0D%0A`;

      let mailTo = `mailto:?bcc=${email}&subject=${subject}&body=${emailBody}`;
      return mailTo;
    },
  },
};
</script>
